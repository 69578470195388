import React, { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  Box,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form';

import { useStore } from '../../../hooks/useStore';
import { COLOR_BORDER_PRIMARY, COLOR_WHITE, COLOR_RED } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';
import Button from '../../../components/buttons/Button';
import CustomModal from '../../../components/modal/CustomModal';
import ContractTermExamples from './ContractTermExamples';
import {
  ContractAnalysisAddTermsSteps,
  ContractAnalysisDefaultInstruction,
  ContractAnalysisDefaultInstruction2,
} from '../../../constants/workflows';

const ContractAnalysisContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const ModalHeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`;

interface UploadTermsProps {
  setIsManual: (isManual: boolean) => void;
  isNextClicked: boolean;
}

const UploadTerms: React.FC<UploadTermsProps> = ({ setIsManual, isNextClicked }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showAddContractTermModal, setShowAddContractTermModal] = useState(false);
  const [showExampleFileds, setShowExampleFields] = useState(false);
  const [parentFormData, setParentFormData] = useState<any[]>([]);
  const [isManuallyTermsAdd, setIsManuallyTermsAdd] = useState(false);

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: { setContractTermsData, setIsTermsManuallyAdded, setUploadedContractTermsData, contractTermsData },
  } = useStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      criteria: [{ title: '' }],
      contractTermExamples: [{ exampleName: '', explanation: '', exampleLanguageExamples: [] }],
      sampleLanguage: '',
      definition: '',
      terms: '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria',
  });

  const {
    fields: exampleFields,
    append: exampleAppend,
    remove: exampleRemove,
  } = useFieldArray({
    control,
    name: 'contractTermExamples',
  });

  const { control: control2 } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      contractTerms: [
        { title: `${ContractAnalysisDefaultInstruction}` },
        { title: `${ContractAnalysisDefaultInstruction2}` },
      ],
    },
  });

  const {
    fields: contractDataFields,
    append: contractDataAppend,
    remove: contractDataRemove,
  } = useFieldArray({
    control: control2,
    name: 'contractTerms',
  });

  const contractTerms = useWatch({ control: control2, name: 'contractTerms' });

  useEffect(() => {
    setUploadedContractTermsData(contractTerms);
    setIsTermsManuallyAdded(false);
  }, [contractTerms]);

  const handleNext = async () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSend = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (data: any) => {
    data.contractTermExamples.shift();
    setParentFormData((curr: any) => [...curr, data]);
    setContractTermsData(data);
    setIsTermsManuallyAdded(true);
    reset();
    setShowAddContractTermModal(false);
    setActiveStep(0);
  };

  const handleChildFormSubmit = (data: any) => {
    exampleAppend(data);
  };

  const handleFinalSubmit = () => {
    switch (activeStep) {
      case 0:
        trigger(['terms', 'definition']).then(() => {
          const response = validateFieldsStepWise();
          handleRedirection(response);
        });
        break;
      case 1:
        trigger(['criteria']).then(() => {
          const response = validateFieldsStepWise();
          handleRedirection(response);
        });
        break;
      case 2:
        trigger(['contractTermExamples']).then(() => {
          const response = validateFieldsStepWise();
          handleRedirection(response);
        });
        break;
      case 3:
        trigger(['sampleLanguage']).then(() => {
          const response = validateFieldsStepWise();
          handleRedirection(response);
        });
        break;

      default:
        break;
    }
  };

  const handleRedirection = (response: any) => {
    if (response) {
      if (activeStep === ContractAnalysisAddTermsSteps.length - 1) {
        handleSend();
      } else {
        if (activeStep === 2 && exampleFields.length < 2) {
          return;
        } else {
          handleNext();
        }
      }
    }
  };

  const validateFieldsStepWise = () => {
    if (activeStep === 0) {
      if (errors.terms || errors.definition) {
        return false;
      } else {
        return true;
      }
    } else if (activeStep === 1) {
      if (errors.criteria) {
        return false;
      } else {
        return true;
      }
    } else if (activeStep === 2) {
      if (errors.contractTermExamples) {
        return false;
      } else {
        return true;
      }
    } else {
      if (errors.sampleLanguage) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleCloseExampleFields = () => {
    setShowExampleFields(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', padding: '24px' }}>
        <ContractAnalysisContainer>
          <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
            {i18n.t('workFlows.contractAnalysis.stepper.terms.heading')}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 400 }}>
            {i18n.t('Enter contract terms including a section, definition and examples.')}
          </Typography>
        </ContractAnalysisContainer>
        <ContractAnalysisContainer>
          <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'center' }}>
            {i18n.t('Upload terms')}
          </Typography>
          {isManuallyTermsAdd ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{i18n.t('workFlows.contractAnalysis.uploadTerms.table.name')}</TableCell>
                      <TableCell>{i18n.t('workFlows.contractAnalysis.uploadTerms.table.criteria')}</TableCell>
                      <TableCell>{i18n.t('workFlows.contractAnalysis.uploadTerms.table.example')}</TableCell>
                      <TableCell>{i18n.t('workFlows.contractAnalysis.uploadTerms.table.sampleLanguage')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parentFormData?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data.terms}</TableCell>
                        <TableCell>
                          {data.criteria.map((criteriaItem: any, criteriaIndex: number) => (
                            <span key={criteriaIndex}>
                              {criteriaItem.title} {criteriaIndex === data.criteria.length - 1 ? '' : ', '}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell>
                          {data.contractTermExamples.map((example: any, exampleIndex: number) => (
                            <span key={exampleIndex}>
                              {example.exampleName}
                              {exampleIndex === data.contractTermExamples.length - 1 ? '' : ', '}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell> {data.sampleLanguage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {isNextClicked && contractTermsData.length < 1 && (
                <p style={{ color: COLOR_RED, fontSize: '16px' }}>Required!!</p>
              )}
              <Button
                sx={{
                  padding: '9px 16px !important',
                  height: '40px !important',
                  width: 'fit-content',
                  fontWeight: 400,
                  marginRight: '8px',
                  marginLeft: 'auto',
                }}
                onClick={() => {
                  setShowAddContractTermModal(true);
                }}
              >
                {i18n.t('workFlows.contractAnalysis.uploadTerms.addTerm')}
              </Button>
              <Button
                sx={{
                  padding: '9px 16px !important',
                  height: '40px !important',
                  width: 'fit-content',
                  fontWeight: 400,
                  marginRight: '8px',
                }}
                onClick={() => {
                  setIsManuallyTermsAdd(false);
                }}
              >
                {i18n.t('workFlows.contractAnalysis.uploadTerms.uploadText')}
              </Button>
            </>
          ) : (
            <>
              <form>
                {contractDataFields.map((field: any, index: number) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
                    <Box key={field.id} sx={{ width: '100%' }}>
                      <Controller
                        control={control2}
                        name={`contractTerms.${index}.title`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          <TextField
                            placeholder="Terms"
                            onChange={onChange}
                            value={value}
                            ref={ref}
                            multiline
                            rows={5}
                            sx={{ width: '100%' }}
                            InputProps={{
                              sx: {
                                overflowY: 'auto',
                                '& textarea': {
                                  height: 'auto',
                                  maxHeight: '15ch',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        id="removeModel"
                        onClick={() => contractDataRemove(index)}
                        sx={{
                          background: 'blue',
                          color: COLOR_WHITE,
                          borderRadius: '8px',
                          ':hover': { background: COLOR_RED },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </form>
              {isNextClicked && <p style={{ color: COLOR_RED, fontSize: '16px' }}>Required!!</p>}

              <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                <Button
                  sx={{
                    padding: '9px 16px !important',
                    height: '40px !important',
                    width: 'fit-content',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    setIsManuallyTermsAdd(true);
                    setIsManual(true);
                  }}
                >
                  {i18n.t('workFlows.contractAnalysis.uploadTerms.addManually')}
                </Button>
                <Button
                  sx={{
                    padding: '9px 16px !important',
                    height: '40px !important',
                    width: 'fit-content',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    contractDataAppend({ title: '' });
                  }}
                >
                  <AddIcon
                    sx={{
                      borderRadius: '4px',
                      cursor: 'pointer',
                      height: '40px !important',
                      width: 'fit-content',
                    }}
                  />
                  {i18n.t('workFlows.contractAnalysis.uploadTerms.addTerm')}
                </Button>
              </Box>
            </>
          )}
        </ContractAnalysisContainer>
      </Box>
      {showAddContractTermModal && (
        <CustomModal
          isOpen={showAddContractTermModal}
          onClose={() => {
            setShowAddContractTermModal(false);
          }}
          closeOnBackdropClick
          key={'upload-terms'}
          sx={{ width: '60%', height: '80%' }}
        >
          <PageContainer sx={{ width: '100%', overflowY: 'scroll' }}>
            <ModalHeaderBox>
              <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
                {i18n.t('Contract terms')}
              </Typography>
              <IconButton
                onClick={() => {
                  setShowAddContractTermModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </ModalHeaderBox>
            <Stepper activeStep={activeStep} sx={{ marginBottom: '2rem' }}>
              {ContractAnalysisAddTermsSteps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <form onSubmit={handleSubmit(onSubmit)}>
              {activeStep === 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <Controller
                    control={control}
                    name="terms"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <TextField label="Terms" sx={{ width: '100%' }} onChange={onChange} value={value} ref={ref} />
                    )}
                  />
                  {errors?.terms?.type === 'required' && (
                    <p key={'termsError'} style={{ color: COLOR_RED }}>
                      Required!
                    </p>
                  )}

                  <Controller
                    control={control}
                    name="definition"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <TextField
                        label="Definition"
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        multiline
                        rows={4}
                      />
                    )}
                  />
                  {errors?.definition?.type === 'required' && (
                    <p key={'definitionError'} style={{ color: COLOR_RED }}>
                      Required!
                    </p>
                  )}
                </Box>
              )}
              {activeStep === 1 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <Typography variant="h4" sx={{ fontSize: '22px', fontWeight: 700 }}>
                    {i18n.t('Criteria')}
                  </Typography>
                  {fields.map((field: any, index: number) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <Box flex={11} key={field.id}>
                        <Controller
                          control={control}
                          name={`criteria.${index}.title`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value, ref } }) => (
                            <TextField
                              label="Criteria"
                              sx={{ width: '100%' }}
                              onChange={onChange}
                              value={value}
                              ref={ref}
                            />
                          )}
                        />
                        {errors?.criteria?.[index]?.title?.type === 'required' && (
                          <p style={{ color: COLOR_RED }}>Required!</p>
                        )}
                      </Box>
                      <Box flex={1}>
                        <IconButton
                          id="removeModel"
                          onClick={() => remove(index)}
                          sx={{
                            background: 'blue',
                            color: COLOR_WHITE,
                            borderRadius: '8px',
                            ':hover': { background: COLOR_RED },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                  <Box sx={{ marginTop: '1rem', justifyContent: 'right', display: 'flex' }}>
                    <Button
                      id="addCriteria"
                      onClick={() => {
                        append({ title: '' });
                      }}
                      sx={{ width: 'auto' }}
                    >
                      {i18n.t('Add Criteria')}
                    </Button>
                  </Box>
                </Box>
              )}
              {activeStep === 2 && (
                <ContractAnalysisContainer>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Example</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {exampleFields.slice(1).map((example, index) => (
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{example?.exampleName}</TableCell>
                            <TableCell>
                              <Box>
                                <IconButton
                                  id="removeModel"
                                  onClick={() => exampleRemove(index)}
                                  sx={{
                                    background: 'blue',
                                    color: COLOR_WHITE,
                                    borderRadius: '8px',
                                    ':hover': { background: COLOR_RED },
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {!showExampleFileds && (
                    <Button
                      sx={{
                        padding: '9px 16px !important',
                        height: '40px !important',
                        width: 'fit-content',
                        fontWeight: 400,
                        marginRight: '8px',
                      }}
                      onClick={() => {
                        setShowExampleFields(true);
                      }}
                    >
                      {i18n.t('Add')}
                    </Button>
                  )}

                  {showExampleFileds && (
                    <ContractTermExamples
                      onSubmit={handleChildFormSubmit}
                      closeExampleFields={handleCloseExampleFields}
                    />
                  )}
                </ContractAnalysisContainer>
              )}
              {activeStep === 3 && (
                <Box>
                  <Controller
                    control={control}
                    name={'sampleLanguage'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <TextField
                        label="Sample Language"
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        multiline
                      />
                    )}
                  />
                  {errors?.sampleLanguage?.type === 'required' && <p style={{ color: COLOR_RED }}>Required!</p>}
                </Box>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  {i18n.t('Back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleFinalSubmit} type="submit">
                  {activeStep === ContractAnalysisAddTermsSteps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </form>
          </PageContainer>
        </CustomModal>
      )}
    </>
  );
};

export default observer(UploadTerms);
