import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { WorkflowApi } from '../api/WorkflowApi';
import { CreateWorkflowRunDto, Workflow, EMPTY_WORKFLOW_RUN, WorkflowRun, EMPTY_WORKFLOW } from '../models/Workflows';

export class WorkflowStore {
  workflowsApi: WorkflowApi;
  selectedWorkflow: Workflow = EMPTY_WORKFLOW;
  selectedWorkflowRun: WorkflowRun = EMPTY_WORKFLOW_RUN;
  workflows: Workflow[] = [];
  workflowRuns: WorkflowRun[] = [];

  appName: string = '';
  contractFile: any = {};
  contractTermsData: any = [];
  uploadedContractTermsData: any = [];
  isTermsManuallyAdded: boolean = false;

  constructor(workflowsApi: WorkflowApi) {
    this.workflowsApi = workflowsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedWorkflow(selectedWorkflow: Workflow) {
    runInAction(() => {
      this.selectedWorkflow = selectedWorkflow;
    });
  }

  async getWorkflowsForOrganization(organizationId: string) {
    const workflows = await this.workflowsApi.getAllWorkflowsByOrganizaton(organizationId);

    runInAction(() => {
      this.workflows = workflows;
    });
  }

  //Run
  async createWorkflowRun(createWorkflowRunDto: CreateWorkflowRunDto) {
    const run = await this.workflowsApi.createWorkflowRun(createWorkflowRunDto);

    runInAction(() => {
      this.selectedWorkflowRun = run;
    });

    return run;
  }

  async getWorkflowRunsByUserId(userId: string) {
    const runs = await this.workflowsApi.findAllRunsByUserId(userId);

    runInAction(() => {
      this.workflowRuns = runs;
    });
  }

  // Contract Analysis
  setAppName(text: string) {
    runInAction(() => {
      this.appName = text;
    });
  }

  setContractFile(file: object) {
    runInAction(() => {
      this.contractFile = file;
    });
  }

  setContractTermsData(contractTerms: any) {
    runInAction(() => {
      this.contractTermsData = [...this.contractTermsData, contractTerms];
    });
  }

  setUploadedContractTermsData(contractTerms: any) {
    runInAction(() => {
      this.uploadedContractTermsData = [contractTerms];
    });
  }

  setIsTermsManuallyAdded(booleanChange: boolean) {
    runInAction(() => {
      this.isTermsManuallyAdded = booleanChange;
    });
  }
}
