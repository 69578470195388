import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';
import { Paths } from '../../../constants/routes';

import { useStore } from '../../../hooks/useStore';
import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import Button from '../../../components/buttons/Button';
import CustomModal from '../../../components/modal/CustomModal';
import { AllWorkflows, WorkflowRunStatuses } from '../../../constants/workflows';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import { SortOptionsContracts } from '../../../constants/sortOptions';
import { SortContractFormulas } from '../../../utils/sortContracts';
import { Workflow } from '../../../models/Workflows';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const AllWorkflowsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: 16rem;
  height: 15rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const ResultDetailsContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f3f3f3;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
`;

const StyledSpan = styled.span`
  font-weight: 500;
`;

const DownloadOptionBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

const WorkflowPage = () => {
  const {
    localizationStore: { i18next: i18n },
    workFlowStore: {
      getWorkflowRunsByUserId,
      setAppName,
      getWorkflowsForOrganization,
      workflowRuns,
      workflows,
      setSelectedWorkflow,
    },
    conversationStore: { setAgentFiles },
    userStore: { userData },
  } = useStore();

  const [filteredWorkflows, setFilteredworkflows] = useState<any>([]);
  const [viewContractAnalysisResultModal, setViewContractAnalysisResultModal] = useState(false);
  const [contractAnalysisResult, setContractAnalysisResult] = useState();
  const [currentContractAnalysisDetails, setCurrentContractAnalysisDetails] = useState<any>();
  const [page, setPage] = React.useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortOrder, setSortOrder] = useState(SortOptionsContracts.nameAtoZ);

  const itemsPerPage = 10;
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const navigate = useNavigate();

  const handleCreateWorkflow = async (workflow: Workflow) => {
    setSelectedWorkflow(workflow);
    switch (workflow.name) {
      case AllWorkflows.contractAnalysis:
        setAppName('');
        setAgentFiles([]);
        navigate(`${Paths.WORKFLOWS}/form`);
        break;

      case AllWorkflows.callTranscriptSummarization:
        setAgentFiles([]);
        navigate(`${Paths.WORKFLOWS}/call-transcript-summarization`);
        break;

      default:
        break;
    }
  };

  const getWorkflowRunsByUser = async () => {
    await getWorkflowRunsByUserId(userData.id);
  };

  const handleViewContractAnalysisResult = (data: any) => {
    if (data.result && data.status === WorkflowRunStatuses.DONE) {
      setCurrentContractAnalysisDetails(data);
      setContractAnalysisResult(data.result);
      setViewContractAnalysisResultModal(true);
    }
  };

  const handleDownloadContractAnalysisResult = () => {
    const fileName = currentContractAnalysisDetails?.variables.name;

    const table = document.getElementById('resultTable')?.getElementsByTagName('table');
    const rows = table?.[0].querySelectorAll('tr');

    let csvContent = 'data:text/csv;charset=utf-8,';

    rows?.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('th, td');
      let rowValues: any = [];
      cells.forEach((cell, cellIndex) => {
        rowValues.push(cell?.textContent?.trim().replaceAll(',', ' ').replaceAll('\n', ' '));
      });
      csvContent += rowValues.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleRefresh = () => {
    getWorkflowRunsByUser();
  };

  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userData?.organization?.id) {
      getWorkflowsForOrganization(userData?.organization?.id);
    }
  }, [userData?.organization?.id]);

  useEffect(() => {
    if (userData?.id) {
      getWorkflowRunsByUser();
    }
  }, [userData?.id]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredworkflows(workflowRuns);
    }
    const filteredOptions = workflowRuns?.filter(
      (item: any) => item?.variables?.name?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
    setFilteredworkflows(filteredOptions);
  }, [searchValue, workflowRuns]);

  useEffect(() => {
    const sortFunction = SortContractFormulas[sortOrder];

    if (searchValue) {
      setFilteredworkflows(
        sortFunction(
          workflowRuns.filter((workflow: any) => workflow?.variables?.name?.toLowerCase().includes(searchValue))
        )
      );
      return;
    }

    setFilteredworkflows(sortFunction(workflowRuns));
  }, [searchValue, workflowRuns, sortOrder]);

  return (
    <FullHeightScreenWrapper
      sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', flexDirection: 'row' }}
    >
      <PageContainer>
        <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 700 }}>
          {i18n.t('sidebarMenu.workflows.action')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
          {workflows.map((workflow, indedx) => (
            <AllWorkflowsContainer key={indedx}>
              <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
                {i18n.t(workflow.name)}
              </Typography>
              <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
                {i18n.t(workflow.category)}
              </Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Button
                  sx={{
                    padding: '9px 16px !important',
                    height: '40px !important',
                    width: 'fit-content',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  onClick={() => handleCreateWorkflow(workflow)}
                >
                  {i18n.t('workFlows.contractAnalysis.tryThis')}
                </Button>
              </Box>
            </AllWorkflowsContainer>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            marginBottom: '1rem',
          }}
        >
          <IconButton onClick={() => handleRefresh()}>
            <RefreshIcon
              fontSize={'large'}
              sx={{ border: '1px solid ', borderRadius: '50%', padding: '2px', color: '#131F4D' }}
            ></RefreshIcon>
          </IconButton>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <CustomSearchBar
              sx={{ width: '100%' }}
              onChange={setSearchValue}
              onCancelSearch={() => setSearchValue('')}
              placeholder={'Search by name'}
            />
            <TextField
              sx={{ width: '100%' }}
              id="sort-analysis"
              value={sortOrder}
              select
              onChange={(event: any) => setSortOrder(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ width: '100px', marginRight: '14px' }} position="start">
                    Sort:
                  </InputAdornment>
                ),
              }}
            >
              {[
                SortOptionsContracts.nameAtoZ,
                SortOptionsContracts.nameZtoA,
                SortOptionsContracts.oldestFirst,
                SortOptionsContracts.newestFirst,
                SortOptionsContracts.ownerAtoZ,
                SortOptionsContracts.ownerZtoA,
              ]?.map((item, index) => (
                <MenuItem value={item} key={`sort-contract-${index}`}>
                  <Typography variant={'subtitle2'}>{i18n.t(`workFlows.contractAnalysis.sort.${item}`)}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Workflow</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWorkflows
                ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((data: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{data?.variables?.name}</TableCell>
                    <TableCell>{data?.workflow?.name}</TableCell>
                    <TableCell>{data?.workflow?.category}</TableCell>
                    <TableCell>{data?.user?.email}</TableCell>
                    <TableCell>{data?.status === 'IN_PROGRESS' ? 'IN PROGRESS' : data.status}</TableCell>
                    <TableCell>
                      {data?.startedAt
                        .slice(0, -5)
                        .split('T')
                        .map((part: any) => part)
                        .join(' ')}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size={'small'}
                        onClick={() => handleViewContractAnalysisResult(data)}
                        sx={{
                          color:
                            data.result && data.status === WorkflowRunStatuses.DONE
                              ? COLOR_PRIMARY
                              : GRAY_COLORS.GRAY_700,
                        }}
                        title="view result"
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          sx={{ justifyContent: 'end', marginTop: '20px' }}
          count={Math.ceil(workflowRuns.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </PageContainer>
      {viewContractAnalysisResultModal && (
        <CustomModal
          isOpen={viewContractAnalysisResultModal}
          onClose={() => {
            setViewContractAnalysisResultModal(false);
          }}
          closeOnBackdropClick
          sx={{
            width: '90%',
            height: '90%',
            flexDirection: 'column',
          }}
        >
          <DownloadOptionBox>
            <Box sx={{ textAlign: 'right', display: 'flex', gap: '16px', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
                {i18n.t(`${currentContractAnalysisDetails.workflow.name} result`)}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                setViewContractAnalysisResultModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DownloadOptionBox>
          <ResultDetailsContainer>
            <Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  {i18n.t('Name : ')}
                  <StyledSpan>{currentContractAnalysisDetails.variables.name}</StyledSpan>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  {i18n.t('Status : ')}
                  <StyledSpan>{currentContractAnalysisDetails.status}</StyledSpan>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  {i18n.t('Workflow : ')}
                  <StyledSpan>{currentContractAnalysisDetails.workflow.name}</StyledSpan>
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  {i18n.t('Owner : ')}
                  <StyledSpan>{currentContractAnalysisDetails.user.email}</StyledSpan>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  {i18n.t('Created At : ')}
                  <StyledSpan>{currentContractAnalysisDetails.completedAt}</StyledSpan>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  {i18n.t('Category : ')}
                  <StyledSpan>{currentContractAnalysisDetails.workflow.category}</StyledSpan>
                </Typography>
              </Box>
            </Box>
          </ResultDetailsContainer>
          <Box sx={{ width: '100%', textAlign: 'right', marginBottom: '1rem' }}>
            <Button
              sx={{
                padding: '9px 16px !important',
                height: '40px !important',
                width: 'fit-content',
                fontWeight: 400,
                marginRight: '8px',
              }}
              onClick={() => handleDownloadContractAnalysisResult()}
            >
              {i18n.t('workFlows.contractAnalysis.contractAnalysis.downloadResult')}
            </Button>
          </Box>
          {currentContractAnalysisDetails.workflow.name === AllWorkflows.callTranscriptSummarization && (
            <Box sx={{ overflowY: 'auto' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '12px' }}>
                  <TabList onChange={handleChange} aria-label="call transcript summarization tabs">
                    {currentContractAnalysisDetails?.result?.map((resultData: any, index: number) => (
                      <Tab label={resultData?.FileID} value={`${index + 1}`} />
                    ))}
                  </TabList>
                </Box>
                {currentContractAnalysisDetails?.result?.map((row: any, index: number) => (
                  <TabPanel value={`${index + 1}`} sx={{ padding: '0px' }}>
                    <TableContainer component={Paper}>
                      <ResultDetailsContainer sx={{ flexDirection: 'column' }}>
                        <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 700 }}>
                          {i18n.t('Response Summary :')}
                        </Typography>
                        {currentContractAnalysisDetails?.result?.[index]?.ResponseSummary}
                      </ResultDetailsContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{i18n.t('workFlows.callTranscriptSummarization.question')}</TableCell>
                            <TableCell>{i18n.t('workFlows.callTranscriptSummarization.response')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row?.QueryResponses?.map((response: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{response?.question}</TableCell>
                              <TableCell>{response?.response}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          )}
          {currentContractAnalysisDetails.workflow.name === AllWorkflows.contractAnalysis && (
            <PageContainer id="resultTable">
              <div
                dangerouslySetInnerHTML={{
                  __html: contractAnalysisResult ? contractAnalysisResult : '',
                }}
              />
            </PageContainer>
          )}
        </CustomModal>
      )}
    </FullHeightScreenWrapper>
  );
};

export default observer(WorkflowPage);
