import { ReactNode, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

import { useStore } from '../../../hooks/useStore';
import { COLOR_WHITE } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';
import { Paths } from '../../../constants/routes';
import Button from '../../../components/buttons/Button';
import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import UploadContracts from './UploadContracts';
import UploadTerms from './UploadTerms';
import {
  ContractAnalysisSteps,
  ContractAnalysisTermsStringSeparator,
  WorkflowRunStatuses,
} from '../../../constants/workflows';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const ContractWorkflow = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: {
      createWorkflowRun,
      contractTermsData,
      appName,
      uploadedContractTermsData,
      isTermsManuallyAdded,
      selectedWorkflow,
    },
    conversationStore: { fortressFiles },
    userStore: { userData },
  } = useStore();

  const handleNext = async () => {
    setIsNextClicked(true);
    switch (activeStep) {
      case 0:
        if (!appName || appName === '' || fortressFiles.length === 0) return;
        else setIsNextClicked(false);
        break;
      case 1:
        if (isManual) {
          if (contractTermsData.length < 1) return;
          else setIsNextClicked(false);
        } else {
          if (uploadedContractTermsData[0][0].title.trim() === '') return;
          else setIsNextClicked(false);
        }
        break;
      default:
        break;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSend = async () => {
    setLoading(true);
    let contractTermsString;

    if (isTermsManuallyAdded) {
      contractTermsString = makeStringFromData(contractTermsData);
    } else {
      const finalArray = uploadedContractTermsData.map((term: any) => term.map((data: any) => data.title)).flat();
      contractTermsString = finalArray;
    }

    const variableField = {
      name: appName,
      filePath: fortressFiles[0].key,
      contractTerms: contractTermsString?.join(ContractAnalysisTermsStringSeparator),
    };

    const data = {
      variables: variableField,
      workflowId: selectedWorkflow.id,
      userId: userData.id,
      status: WorkflowRunStatuses.IN_PROGRESS,
    };
    await createWorkflowRun(data);
    navigate(`${Paths.WORKFLOWS}`);
    setLoading(false);
  };

  const handleBackToLists = () => {
    navigate(`${Paths.WORKFLOWS}`);
  };

  return (
    <FullHeightScreenWrapper sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', gap: '1rem' }}>
      <Button
        sx={{
          width: 'fit-content',
          marginBottom: '1rem',
        }}
        onClick={handleBackToLists}
      >
        {i18n.t('Back')}
      </Button>
      <PageContainer>
        <Stepper activeStep={activeStep}>
          {ContractAnalysisSteps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>
          {activeStep === 0 && (
            <UploadContracts
              isNextClicked={isNextClicked}
              uploadFileInputText={'Upload Contracts'}
              setLoading={setLoading}
            />
          )}
          {activeStep === 1 && <UploadTerms isNextClicked={isNextClicked} setIsManual={setIsManual} />}
          {activeStep === 2 && (
            <Typography sx={{ mt: 2, mb: 1 }}>{i18n.t('workFlows.contractAnalysis.submit.message')}</Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} loading={loading}>
              {i18n.t('common.back.action')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              onClick={activeStep === ContractAnalysisSteps.length - 1 ? handleSend : handleNext}
              loading={loading}
            >
              {activeStep === ContractAnalysisSteps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
        </>
      </PageContainer>
    </FullHeightScreenWrapper>
  );
};

const makeStringFromData = (responseObjectArray: any): string[] => {
  return responseObjectArray?.map((responseObject: any) => {
    const stringData = `#### ${responseObject.terms}\n* Definition: ${
      responseObject.definition
    }\n* Criteria: \n${responseObject.criteria
      .map((criteriaItem: any) => `- ${criteriaItem.title}`)
      .join('\n')}\n${responseObject.contractTermExamples
      .map(
        (example: any) =>
          `* ${example.exampleType === 'Neutral' ? '' : `${example.exampleType} `}Example:\n#### ${
            example.exampleName
          }\n* Definition:${example.explanation}\n* Criteria:\n${example.exampleLanguageExamples.map(
            (languageExample: any) => `- ${languageExample.title}`
          )}`
      )
      .join('\n')}\n* Sample Language: ${responseObject.sampleLanguage}`;
    return stringData;
  });
};

export default observer(ContractWorkflow);
