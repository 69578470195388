import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStore } from '../../../hooks/useStore';
import { FileAndDocumentKey } from '../../../components/DragAndDropFileInput/DragAndDropFileInput';
import { TypeIcon, getIconByName } from '../../../components/knowledgeBase/utils';
import { getDocumentsKey, uploadDocumentSecurely } from '../../../utils/documentsS3';
import { COLOR_BLACK, COLOR_BORDER_PRIMARY, COLOR_RED, COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { fileStatus } from '../../../constants/fileStatus';
import { S3Folders } from '../../../constants/S3Folders';
import { SIZES_SMALL } from '../../../constants/sizes';
import { getFileExtensionFromName } from '../../../utils/getFileExtensionFromName';
import { ContractAnalysisSupportedFileTypes } from '../../../constants/workflows';
import { convertDocxFileToPdf } from '../../../utils/convertDocxToPdf';

interface UploadContractsProps {
  uploadFileInputText: string;
  isNextClicked: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContractAnalysisContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0;
  gap: 10px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  border: 2px solid ${COLOR_BORDER_PRIMARY};
  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const StyledFileInput = styled.div`
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  p {
    font-size: 0.87rem;
    margin: 0px;
    color: #bbcada;
  }
  input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

const UploadContracts: React.FC<UploadContractsProps> = ({ uploadFileInputText, setLoading, isNextClicked }) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileAndDocumentKey[]>([]);
  const [uploadedFilesEntities, setUploadedFilesEntities] = useState<any[]>([]);
  const [fileValidationError, setFileValidationError] = useState('Required!');
  const fileErrorRef = useRef<HTMLParagraphElement>(null);

  const {
    localizationStore: { i18next: i18n },
    fileStore: { createFile, convertToDocx },
    appState: { s3DocumentsApi },
    conversationStore: { fortressSolutionId, setFortressSolutionFiles, setAgentFiles: setFortressFiles, filesForAgent },
    workFlowStore: { setAppName, appName },
  } = useStore();

  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadInProgress, setIsUploadInProgress] = useState(false);

  const uploadFile = async (fileAndDocumentKey: FileAndDocumentKey) => {
    setLoading(true);
    setIsUploadInProgress(true);
    setUploadedFiles(files => [...files, fileAndDocumentKey]);
    setFortressFiles([...uploadedFiles, fileAndDocumentKey]);

    const createdFileEntry = await createFile({
      key: fileAndDocumentKey.documentKey,
      name: fileAndDocumentKey.file.name,
      status: fileStatus.UPLOADED,
    });

    setUploadedFilesEntities(prevState => {
      setFortressSolutionFiles([...prevState, createdFileEntry]);

      return [...prevState, createdFileEntry];
    });

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(
      fileAndDocumentKey.documentKey,
      fileAndDocumentKey.file.type
    );

    const status = await uploadDocumentSecurely(preSignedPutUrl, fileAndDocumentKey.file, {
      setProgress: (progress: number) =>
        setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: progress })),
    });

    if (status === 200) {
      setIsUploadInProgress(false);
      setLoading(false);
    }
  };

  const handleAddFiles = async (newFiles: File[], fortressSolutionId: string) => {
    const uploadPromises = newFiles.map(async file => {
      const extension = getFileExtensionFromName(file.name);

      if (extension === 'doc' || extension === 'docx') {
        setLoading(true);
        file = await convertDocxFileToPdf(file, convertToDocx);
        setLoading(false);
      }

      return uploadFile({
        file: file,
        documentKey: getDocumentsKey(`${S3Folders.fortress}/${fortressSolutionId}`, file.name),
      });
    });

    await Promise.all(uploadPromises);
  };

  const removeFileByKey = (key: string) => {
    setUploadedFiles(prevState => prevState.filter(fileAndDocumentKey => fileAndDocumentKey.documentKey !== key));

    setUploadProgress(prevState => {
      delete prevState[key];
      return prevState;
    });
  };

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const isSupported = await checkIfFileTypeIsSupported(event?.target?.files);

    if (!isSupported && fileErrorRef.current) {
      fileErrorRef.current.style.display = 'block';
      setFileValidationError(i18n.t('workFlows.contractAnalysis.fileErrorMessage'));
    } else {
      await handleAddFiles([...event.target.files], fortressSolutionId);
    }
  };

  const checkIfFileTypeIsSupported = async (files: any) => {
    const fileNames = Array.from(files).map((file: any) => file.name);

    const allSupported = fileNames.every(fileName => {
      const extension = getFileExtensionFromName(fileName);
      return ContractAnalysisSupportedFileTypes.includes(extension);
    });

    return allSupported;
  };

  useEffect(() => {
    if (filesForAgent.length > 0 && uploadedFiles.length === 0) {
      setUploadedFiles(filesForAgent);
    }
  }, [filesForAgent]);

  useEffect(() => {
    if (fileErrorRef.current) {
      if (isNextClicked && uploadedFiles.length === 0) {
        fileErrorRef.current.style.display = 'block';
        setFileValidationError(i18n.t('workFlows.contractAnalysis.errorMessage'));
      } else {
        fileErrorRef.current.style.display = 'none';
      }
    }
  }, [isNextClicked, uploadedFiles]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', padding: '24px' }}>
        <ContractAnalysisContainer>
          <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
            {i18n.t('workFlows.contractAnalysis.stepper.analysis.heading')}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 400 }}>
            {i18n.t('workFlows.contractAnalysis.subHeader')}
          </Typography>
        </ContractAnalysisContainer>
        <ContractAnalysisContainer>
          <TextField
            label={i18n.t('workFlows.contractAnalysis.appName')}
            placeholder="Your app name"
            onChange={e => {
              setAppName(e.target.value);
            }}
            value={appName}
          />
          {isNextClicked && appName.trim() === '' && (
            <p style={{ color: COLOR_RED, fontSize: '16px', marginTop: '16px !important' }}>
              {i18n.t('workFlows.contractAnalysis.errorMessage')}
            </p>
          )}

          {uploadedFiles.length === 0 && (
            <StyledFileInput>
              <AttachFileIcon sx={{ width: '30px', height: '30px' }} />
              <h3>{uploadFileInputText}</h3>
              <input
                type="file"
                id={'file-input'}
                name={'file-input'}
                onChange={handleChange}
                accept={ContractAnalysisSupportedFileTypes.map(fileType => `.${fileType}`).join(', ')}
              />
            </StyledFileInput>
          )}

          {uploadedFiles.length > 0 && (
            <div>
              {uploadedFiles.map((fileAndDocumentKey, index) => {
                const {
                  file: { name },
                  documentKey,
                } = fileAndDocumentKey;

                return (
                  <div key={fileAndDocumentKey.documentKey} style={{ margin: '12px' }}>
                    <Typography
                      variant={'body2'}
                      sx={{
                        color: COLOR_BLACK,
                        fontWeight: 500,
                        border: `15px solid ${COLOR_BORDER_PRIMARY}`,
                        borderRadius: '6px',
                        padding: '20px',
                        alignItems: 'center',
                        display: 'flex',
                        gap: '10px',
                      }}
                      key={index}
                    >
                      <TypeIcon src={getIconByName(name)} alt={name} style={{ marginRight: 0 }} />
                      {name}
                      <IconButton
                        sx={{
                          '&:hover': {
                            backgroundColor: GRAY_COLORS.GRAY_300,
                          },
                          marginLeft: 'auto',
                        }}
                        onClick={() => removeFileByKey(documentKey)}
                        aria-label="delete-file"
                      >
                        <DeleteIcon
                          sx={{
                            color: 'red',
                          }}
                        />
                      </IconButton>
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
          <p id="fileError" style={{ color: COLOR_RED, fontSize: '16px', display: 'none' }} ref={fileErrorRef}>
            {i18n.t(`${fileValidationError}`)}
          </p>
        </ContractAnalysisContainer>
      </Box>
    </>
  );
};

export default observer(UploadContracts);
