import React, { useState, useEffect } from 'react';
import { Drawer as MuiDrawer, List, ListSubheader, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/core';
import AVMLogo from '../../config/addvaluemachine/AVMLogo.svg';
import chatLogo from '../../config/addvaluemachine/chatLogo.svg';
import agentLogo from '../../config/addvaluemachine/agentLogo.svg';
import feedbackLogo from '../../config/addvaluemachine/feedbackLogo.svg';
import helpCenterLogo from '../../config/addvaluemachine/helpCenterLogo.svg';
import logoutLogo from '../../config/addvaluemachine/logoutLogo.svg';
import promptGalleryLogo from '../../config/addvaluemachine/promptGalleryLogo.svg';
import knowledgeBaseLogo from '../../config/addvaluemachine/knowledgeBaseLogo.svg';
import { useStore } from '../../hooks/useStore';
import { Paths } from '../../constants/routes';
import NavItems from './NavItems';
import FeedbackForm from '../../components/feedbackModal/FeedbackModal';

const drawerWidth = 250;

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : theme.spacing(7) + 1,
    background: '#131F4D',
    color: 'white',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

const useStyles = makeStyles({
  logoImage: {
    maxWidth: '40%',
    paddingLeft: '20px',
    paddingTop: '20px',
    marginBottom: '3rem',
  },
  subheader: {
    background: '#131F4D !important',
    color: 'white !important',
  },
});

interface DrawerComponentProps {
  isDrawerOpen: boolean;
}

interface AdminPortalTab {
  title: string;
  path: string;
  logo: string;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({ isDrawerOpen }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const {
    authStore: { logout },
    localizationStore: { i18next: i18n },
  } = useStore();

  const tabs: AdminPortalTab[] = [
    { title: `${i18n.t('home.newConversation.chat')}`, path: `${Paths.CHAT}`, logo: chatLogo },
    { title: `${i18n.t('promptGallery.header')}`, path: `${Paths.PROMPTS_GALLERY}`, logo: promptGalleryLogo },
    {
      title: `${i18n.t('sidebarMenu.knowledgeBase.action')}`,
      path: `${Paths.KNOWLEDGE_BASE}`,
      logo: knowledgeBaseLogo,
    },
    { title: `${i18n.t('sidebarMenu.agents.action')}`, path: `${Paths.AGENT_LIST}`, logo: agentLogo },
    {
      title: `${i18n.t('sidebarMenu.workflows.action')}`,
      path: `${Paths.WORKFLOWS}`,
      logo: knowledgeBaseLogo,
    },
    {
      title: `${i18n.t('appHeader.logout.action')}`,
      path: '',
      logo: logoutLogo,
    },
    { title: `${i18n.t('sidebarMenu.feedback.action')}`, path: '', logo: feedbackLogo },
    { title: `${i18n.t('sidebarMenu.helpCenter.action')}`, path: `${Paths.HELP_CENTER}`, logo: helpCenterLogo },
  ];

  useEffect(() => {
    const storedItem = localStorage.getItem('selectedItem');
    const matchingTab = tabs.find(tab => location.pathname.startsWith(tab.path));
    setSelectedItem(
      storedItem && tabs.some(tab => tab.title === storedItem) ? storedItem : matchingTab ? matchingTab.title : ''
    );
  }, [location.pathname, tabs]);

  const handleLogout = async () => {
    await logout();
    navigate(Paths.LOGIN);
  };

  const handleItemClick = (title: string) => {
    if (title === `${i18n.t('appHeader.logout.action')}`) {
      handleLogout();
    } else if (title === `${i18n.t('sidebarMenu.feedback.action')}`) {
      // If Feedback is clicked, show the FeedbackComponent
      setShowFeedback(true);
    } else {
      setSelectedItem(title);
      localStorage.setItem('selectedItem', title);
    }
  };
  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };
  return (
    <>
      <Drawer variant="permanent" open={isDrawerOpen}>
        <DrawerHeader sx={{ justifyContent: isDrawerOpen ? 'initial' : '' }}>
          <img src={AVMLogo} alt="AVM Logo" className={isDrawerOpen ? classes.logoImage : ''} />
        </DrawerHeader>
        <div>
          <List>
            {tabs.slice(0, 5).map((text, index) => (
              <NavItems
                key={index}
                text={text}
                isSelected={selectedItem === text.title}
                isDrawerOpen={isDrawerOpen}
                handleItemClick={handleItemClick}
              />
            ))}
          </List>
        </div>
        <div style={{ marginTop: 'auto' }}>
          <List subheader={isDrawerOpen && <ListSubheader className={classes.subheader}>OTHER</ListSubheader>}>
            {tabs.slice(5).map((text, index) => (
              <NavItems
                key={index}
                text={text}
                isSelected={selectedItem === text.title}
                isDrawerOpen={isDrawerOpen}
                handleItemClick={handleItemClick}
              />
            ))}
          </List>
        </div>
      </Drawer>
      {showFeedback && (
        <FeedbackForm
          showFeedback={showFeedback}
          handleCloseFeedback={handleCloseFeedback}
          title={`${i18n.t('sidebarMenu.feedback.title')}`}
          subtitle={`${i18n.t('sidebarMenu.feedback.subtitle')}`}
          typeOfModel={`${i18n.t('sidebarMenu.feedback.typeOfModel')}`}
          saveBtn={`${i18n.t('sidebarMenu.feedback.saveBtn')}`}
        />
      )}
    </>
  );
};

export default DrawerComponent;
