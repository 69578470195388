import { useEffect, useState } from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm, Controller } from 'react-hook-form';

import { TypeIcon, getIconByName } from '../../../components/knowledgeBase/utils';
import { fileStatus } from '../../../constants/fileStatus';
import { getDocumentsKey, uploadDocumentSecurely } from '../../../utils/documentsS3';
import { S3Folders } from '../../../constants/S3Folders';
import { FileAndDocumentKey } from '../../../components/DragAndDropFileInput/DragAndDropFileInput';

import { useStore } from '../../../hooks/useStore';
import { COLOR_WHITE, COLOR_BLACK, COLOR_BORDER_PRIMARY, GRAY_COLORS, COLOR_RED } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';
import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import Button from '../../../components/buttons/Button';
import { WorkflowRunStatuses } from '../../../constants/workflows';
import { Paths } from '../../../constants/routes';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const StyledFileInput = styled(Box)`
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1rem;
  position: relative;
  cursor: pointer;
  margin: 10px 0px;
  border-radius: 20px;
  p {
    font-size: 0.87rem;
    margin: 0px;
    color: #bbcada;
  }
  input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

const ErrorPTag = styled.p`
  margin-top: 10px;
  color: ${COLOR_RED};
`;

const CallTranscriptSummarization = () => {
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<FileAndDocumentKey[]>([]);
  const [uploadedFilesEntities, setUploadedFilesEntities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [questionFile, setQuestionFile] = useState<FileAndDocumentKey[]>([]);

  const {
    localizationStore: { i18next: i18n },
    fileStore: { createFile },
    appState: { s3DocumentsApi },
    conversationStore: { fortressSolutionId, setFortressSolutionFiles, setAgentFiles, filesForAgent },
    workFlowStore: { createWorkflowRun, selectedWorkflow },
    userStore: { userData },
  } = useStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadInProgress, setIsUploadInProgress] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const uploadFile = async (fileAndDocumentKey: FileAndDocumentKey) => {
    setLoading(true);
    setIsUploadInProgress(true);
    setUploadedFiles(files => [...files, fileAndDocumentKey]);
    setAgentFiles([...uploadedFiles, fileAndDocumentKey]);

    const createdFileEntry = await createFile({
      key: fileAndDocumentKey.documentKey,
      name: fileAndDocumentKey.file.name,
      status: fileStatus.UPLOADED,
    });

    setUploadedFilesEntities(prevState => {
      setFortressSolutionFiles([...prevState, createdFileEntry]);

      return [...prevState, createdFileEntry];
    });

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(
      fileAndDocumentKey.documentKey,
      fileAndDocumentKey.file.type
    );

    const status = await uploadDocumentSecurely(preSignedPutUrl, fileAndDocumentKey.file, {
      setProgress: (progress: number) =>
        setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: progress })),
    });

    if (status === 200) {
      setIsUploadInProgress(false);
      setLoading(false);
    }
  };

  const handleAddFiles = async (newFiles: File[], fortressSolutionId: string) => {
    const uploadPromises = newFiles.map(file => {
      return uploadFile({
        file: file,
        documentKey: getDocumentsKey(`${S3Folders.fortress}/${fortressSolutionId}`, file.name),
      });
    });

    await Promise.all(uploadPromises);
  };

  const removeFileByKey = (key: string) => {
    setUploadedFiles(prevState => prevState.filter(fileAndDocumentKey => fileAndDocumentKey.documentKey !== key));

    setUploadProgress(prevState => {
      delete prevState[key];
      return prevState;
    });
  };

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      await handleAddFiles([...event.target.files], fortressSolutionId);
    }
  };

  const uploadQuestionFile = async (fileAndDocumentKey: FileAndDocumentKey) => {
    setLoading(true);
    setIsUploadInProgress(true);
    setQuestionFile([fileAndDocumentKey]);

    const createdFileEntry = await createFile({
      key: fileAndDocumentKey.documentKey,
      name: fileAndDocumentKey.file.name,
      status: fileStatus.UPLOADED,
    });

    setUploadedFilesEntities(prevState => {
      setFortressSolutionFiles([...prevState, createdFileEntry]);

      return [...prevState, createdFileEntry];
    });

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(
      fileAndDocumentKey.documentKey,
      fileAndDocumentKey.file.type
    );

    const status = await uploadDocumentSecurely(preSignedPutUrl, fileAndDocumentKey.file, {
      setProgress: (progress: number) =>
        setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: progress })),
    });

    if (status === 200) {
      setIsUploadInProgress(false);
      setLoading(false);
    }
  };

  const handleAddQuestionFiles = async (newFiles: File[], fortressSolutionId: string) => {
    const uploadPromises = newFiles.map(file => {
      return uploadQuestionFile({
        file: file,
        documentKey: getDocumentsKey(`${S3Folders.fortress}/${fortressSolutionId}`, file.name),
      });
    });

    await Promise.all(uploadPromises);
  };

  const removeQuestionFileByKey = (key: string) => {
    setQuestionFile(prevState => prevState.filter(fileAndDocumentKey => fileAndDocumentKey.documentKey !== key));

    setUploadProgress(prevState => {
      delete prevState[key];
      return prevState;
    });
  };

  const handleQuestionChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      await handleAddQuestionFiles([...event.target.files], fortressSolutionId);
    }
  };

  const handleSubmitData = async (formData: any) => {
    setIsNextClicked(true);
    if (uploadedFiles.length > 0) {
      setIsNextClicked(false);

      const variableField = {
        name: formData.name,
        transcriptFiles: uploadedFiles.map(file => file.documentKey),
        questionFile: questionFile.map(file => file.documentKey),
      };

      const data = {
        variables: variableField,
        workflowId: selectedWorkflow.id,
        userId: userData.id,
        status: WorkflowRunStatuses.IN_PROGRESS,
      };

      await createWorkflowRun(data);
      setUploadedFiles([]);
      setQuestionFile([]);
      reset();
      navigate(`${Paths.WORKFLOWS}`);
    }
  };

  const handleBack = () => {
    setAgentFiles([]);
    navigate(`${Paths.WORKFLOWS}`);
  };

  useEffect(() => {
    if (filesForAgent.length > 0 && uploadedFiles.length === 0) {
      setUploadedFiles(filesForAgent);
    }
  }, [filesForAgent]);

  return (
    <FullHeightScreenWrapper sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', gap: '1rem' }}>
      <Button
        sx={{
          width: 'fit-content',
        }}
        onClick={handleBack}
      >
        {i18n.t('Back')}
      </Button>
      <PageContainer>
        <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 700, marginBottom: '1rem' }}>
          {i18n.t('workFlows.callTranscriptSummarization.heading')}
        </Typography>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Box sx={{ width: '100%', marginBottom: '2rem' }}>
            <Controller
              control={control}
              name={'name'}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  label="Name"
                  sx={{ width: '100%' }}
                  placeholder={'Name'}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                />
              )}
            />
            {errors?.name?.type === 'required' && (
              <ErrorPTag>{i18n.t('workFlows.contractAnalysis.errorMessage')}</ErrorPTag>
            )}
          </Box>

          <Box
            sx={{
              border: `2px solid ${COLOR_BORDER_PRIMARY}`,
              borderRadius: '4px',
              marginBottom: '1rem',
              padding: '0.5rem 1rem',
            }}
          >
            {questionFile?.length === 0 && (
              <StyledFileInput>
                <AttachFileIcon sx={{ width: '30px', height: '30px' }} />
                <h3>{i18n.t('workFlows.callTranscriptSummarization.uploadQuestion')}</h3>
                <input
                  type="file"
                  id={'question-file-input'}
                  name={'question-file-input'}
                  multiple
                  onChange={handleQuestionChange}
                  accept={'.csv'}
                />
              </StyledFileInput>
            )}
            {isNextClicked && questionFile?.length === 0 && (
              <p style={{ color: COLOR_RED, fontSize: '16px' }}>{i18n.t('workFlows.contractAnalysis.errorMessage')}</p>
            )}

            {questionFile.length > 0 && (
              <div>
                {questionFile.map((fileAndDocumentKey, index) => {
                  const {
                    file: { name },
                    documentKey,
                  } = fileAndDocumentKey;

                  return (
                    <div key={fileAndDocumentKey.documentKey} style={{ margin: '12px' }}>
                      <Typography
                        variant={'body2'}
                        sx={{
                          color: COLOR_BLACK,
                          fontWeight: 500,
                          border: `15px solid ${COLOR_BORDER_PRIMARY}`,
                          borderRadius: '6px',
                          padding: '20px',
                          alignItems: 'center',
                          display: 'flex',
                          gap: '10px',
                        }}
                        key={index}
                      >
                        <TypeIcon src={getIconByName(name)} alt={name} style={{ marginRight: 0 }} />
                        {name}
                        <IconButton
                          sx={{
                            '&:hover': {
                              backgroundColor: GRAY_COLORS.GRAY_300,
                            },
                            marginLeft: 'auto',
                          }}
                          onClick={() => removeQuestionFileByKey(documentKey)}
                          aria-label="delete-file"
                        >
                          <DeleteIcon
                            sx={{
                              color: COLOR_RED,
                            }}
                          />
                        </IconButton>
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}
          </Box>
          <Box
            sx={{
              border: `2px solid ${COLOR_BORDER_PRIMARY}`,
              borderRadius: '4px',
              marginBottom: '1rem',
              padding: '0.5rem 1rem',
            }}
          >
            {uploadedFiles.length === 0 && (
              <StyledFileInput>
                <AttachFileIcon sx={{ width: '30px', height: '30px' }} />
                <h3>{i18n.t('workFlows.callTranscriptSummarization.uploadFiles')}</h3>
                <input
                  type="file"
                  id={'file-input'}
                  name={'file-input'}
                  multiple
                  onChange={handleChange}
                  accept={'.pdf'}
                />
              </StyledFileInput>
            )}
            {isNextClicked && uploadedFiles.length === 0 && (
              <p style={{ color: COLOR_RED, fontSize: '16px' }}>{i18n.t('workFlows.contractAnalysis.errorMessage')}</p>
            )}

            {uploadedFiles.length > 0 && (
              <div>
                {uploadedFiles.map((fileAndDocumentKey, index) => {
                  const {
                    file: { name },
                    documentKey,
                  } = fileAndDocumentKey;

                  return (
                    <div key={fileAndDocumentKey.documentKey} style={{ margin: '12px' }}>
                      <Typography
                        variant={'body2'}
                        sx={{
                          color: COLOR_BLACK,
                          fontWeight: 500,
                          border: `15px solid ${COLOR_BORDER_PRIMARY}`,
                          borderRadius: '6px',
                          padding: '20px',
                          alignItems: 'center',
                          display: 'flex',
                          gap: '10px',
                        }}
                        key={index}
                      >
                        <TypeIcon src={getIconByName(name)} alt={name} style={{ marginRight: 0 }} />
                        {name}
                        <IconButton
                          sx={{
                            '&:hover': {
                              backgroundColor: GRAY_COLORS.GRAY_300,
                            },
                            marginLeft: 'auto',
                          }}
                          onClick={() => removeFileByKey(documentKey)}
                          aria-label="delete-file"
                        >
                          <DeleteIcon
                            sx={{
                              color: COLOR_RED,
                            }}
                          />
                        </IconButton>
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}
          </Box>
          <Box sx={{ textAlign: 'right', marginTop: '1rem' }}>
            <Button
              sx={{
                width: 'fit-content',
              }}
              type="submit"
              onClick={handleSubmit(handleSubmitData)}
              loading={loading}
            >
              {i18n.t('Submit')}
            </Button>
          </Box>
        </form>
      </PageContainer>
    </FullHeightScreenWrapper>
  );
};

export default observer(CallTranscriptSummarization);
