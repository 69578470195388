import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { IconButton, TextField, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { COLOR_BLACK, COLOR_BORDER_PRIMARY, GRAY_COLORS } from '../../constants/colors';
import { TypeIcon, getIconByName } from '../knowledgeBase/utils';
import { fileStatus } from '../../constants/fileStatus';
import { getDocumentsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import { S3Folders } from '../../constants/S3Folders';
import { FileAndDocumentKey } from '../DragAndDropFileInput/DragAndDropFileInput';
import { useStore } from '../../hooks/useStore';
import { getFileExtensionFromName } from '../../utils/getFileExtensionFromName';
import { convertDocxFileToPdf } from '../../utils/convertDocxToPdf';

interface AskQuestionsProps {
  activeStep: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledFileInput = styled.div`
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1rem;
  position: relative;
  cursor: pointer;
  margin: 20px 0px;
  border-radius: 20px;
  p {
    font-size: 0.87rem;
    margin: 0px;
    color: #bbcada;
  }
  input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
`;
const AskQuestions: React.FC<AskQuestionsProps> = ({ activeStep, setLoading }) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileAndDocumentKey[]>([]);
  const [uploadedFilesEntities, setUploadedFilesEntities] = useState<any[]>([]);

  const {
    fileStore: { createFile, convertToDocx },
    appState: { s3DocumentsApi },
    conversationStore: {
      fortressSolutionId,
      setFortressSolutionFiles,
      fortressQuestions,
      setFortressQuestions,
      setAgentFiles: setFortressFiles,
      filesForAgent,
    },
  } = useStore();

  const handleAddIconClick = () => {
    const lastTextField = fortressQuestions[fortressQuestions.length - 1];
    if (!lastTextField || lastTextField.value.trim() !== '') {
      const lastTextFieldId = lastTextField ? lastTextField.id : '0';
      const newTextField = { id: (parseInt(lastTextFieldId) + 1).toString(), value: '' };
      setFortressQuestions([...fortressQuestions, newTextField]);
    }
  };

  const handleTextFieldChange = (index: number, value: string) => {
    const newTextField = [...fortressQuestions];
    newTextField[index].value = value;
    setFortressQuestions(newTextField);
  };

  const handleDeleteClick = (index: number) => {
    const newTextField = [...fortressQuestions];
    newTextField.splice(index, 1);
    setFortressQuestions(newTextField);
  };

  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadInProgress, setIsUploadInProgress] = useState(false);

  const uploadFile = async (fileAndDocumentKey: FileAndDocumentKey) => {
    setLoading(true);
    setIsUploadInProgress(true);
    setUploadedFiles(files => [...files, fileAndDocumentKey]);
    setFortressFiles([...uploadedFiles, fileAndDocumentKey]);

    const createdFileEntry = await createFile({
      key: fileAndDocumentKey.documentKey,
      name: fileAndDocumentKey.file.name,
      status: fileStatus.UPLOADED,
    });

    setUploadedFilesEntities(prevState => {
      setFortressSolutionFiles([...prevState, createdFileEntry]);

      return [...prevState, createdFileEntry];
    });

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(
      fileAndDocumentKey.documentKey,
      fileAndDocumentKey.file.type
    );

    const status = await uploadDocumentSecurely(preSignedPutUrl, fileAndDocumentKey.file, {
      setProgress: (progress: number) =>
        setUploadProgress(prevState => ({ ...prevState, [fileAndDocumentKey.documentKey]: progress })),
    });

    if (status === 200) {
      setIsUploadInProgress(false);
      setLoading(false);
    }
  };

  const handleAddFiles = async (newFiles: File[], fortressSolutionId: string) => {
    const uploadPromises = newFiles.map(async file => {
      const extension = getFileExtensionFromName(file.name);

      if (extension === 'doc' || extension === 'docx') {
        file = await convertDocxFileToPdf(file, convertToDocx);
      }

      return uploadFile({
        file: file,
        documentKey: getDocumentsKey(`${S3Folders.fortress}/${fortressSolutionId}`, file.name),
      });
    });

    await Promise.all(uploadPromises);
  };

  const removeFileByKey = (key: string) => {
    setUploadedFiles(prevState => prevState.filter(fileAndDocumentKey => fileAndDocumentKey.documentKey !== key));

    setUploadProgress(prevState => {
      delete prevState[key];
      return prevState;
    });
  };

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      await handleAddFiles([...event.target.files], fortressSolutionId);
    }
  };

  useEffect(() => {
    if (filesForAgent.length > 0 && uploadedFiles.length === 0) {
      setUploadedFiles(filesForAgent);
    }
  }, [filesForAgent]);

  return (
    <>
      {activeStep ? (
        <div>
          {fortressQuestions.map((textField, index) => (
            <div key={textField.id} style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
              <TextField
                sx={{ width: '100%' }}
                placeholder={`Question ${index + 1}`}
                multiline
                maxRows={4}
                disabled={index !== fortressQuestions.length - 1}
                value={textField.value}
                onChange={e => handleTextFieldChange(index, e.target.value)}
              />

              {index !== fortressQuestions.length - 1 && (
                <IconButton
                  sx={{
                    '&:hover': {
                      backgroundColor: GRAY_COLORS.GRAY_300,
                    },
                  }}
                  onClick={() => handleDeleteClick(index)}
                  aria-label="delete"
                >
                  <DeleteIcon
                    sx={{
                      color: 'red',
                    }}
                  />
                </IconButton>
              )}
            </div>
          ))}
          <div>
            <AddIcon
              onClick={handleAddIconClick}
              sx={{
                color: 'white',
                width: '50px',
                height: '50px',
                background: 'blue',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          {uploadedFiles.length == 0 && (
            <StyledFileInput>
              <AttachFileIcon sx={{ width: '30px', height: '30px' }} />
              <h3>Click box to upload</h3>
              <input type="file" id={'file-input'} name={'file-input'} multiple onChange={handleChange} />
            </StyledFileInput>
          )}

          {uploadedFiles.length > 0 && (
            <div>
              {uploadedFiles.map((fileAndDocumentKey, index) => {
                const {
                  file: { name },
                  documentKey,
                } = fileAndDocumentKey;

                return (
                  <div key={fileAndDocumentKey.documentKey} style={{ margin: '12px' }}>
                    <Typography
                      variant={'body2'}
                      sx={{
                        color: COLOR_BLACK,
                        fontWeight: 500,
                        border: `15px solid ${COLOR_BORDER_PRIMARY}`,
                        borderRadius: '6px',
                        padding: '20px',
                        alignItems: 'center',
                        display: 'flex',
                        gap: '10px',
                      }}
                      key={index}
                    >
                      <TypeIcon src={getIconByName(name)} alt={name} style={{ marginRight: 0 }} />
                      {name}
                      <IconButton
                        sx={{
                          '&:hover': {
                            backgroundColor: GRAY_COLORS.GRAY_300,
                          },
                          marginLeft: 'auto',
                        }}
                        onClick={() => removeFileByKey(documentKey)}
                        aria-label="delete-file"
                      >
                        <DeleteIcon
                          sx={{
                            color: 'red',
                          }}
                        />
                      </IconButton>
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default observer(AskQuestions);
