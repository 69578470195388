import { ReactNode, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import Chat from '@mui/icons-material/Chat';
import { ListItemButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

import { COLOR_MENU_ITEM_SELECTED, COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import Button from '../../../components/buttons/Button';
import WarningModal from '../../../components/modal/WarningModal';
import { Paths } from '../../../constants/routes';

const StyledListItemButton = styled(ListItemButton)`
  border-radius: 8px;
  padding: 4px 8px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledListItemText = styled(Typography)`
  color: ${GRAY_COLORS.GRAY_500};
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

interface MenuItemProps {
  selected: boolean;
  onClick: () => void;
  icon: ReactNode;
  text: string;
}
const MenuItem = ({ selected, onClick, icon, text }: MenuItemProps) => {
  return (
    <StyledListItemButton
      selected={selected}
      onClick={onClick}
      sx={{ backgroundColor: selected ? `${COLOR_MENU_ITEM_SELECTED} !important` : 'inherit' }}
    >
      {icon}
      <StyledListItemText variant={'body2'} sx={{ color: selected ? COLOR_PRIMARY : 'inherit' }}>
        {text}
      </StyledListItemText>
    </StyledListItemButton>
  );
};

interface HomePageCollapsedMenuProps {
  openTabs: () => void;
  activeTab: number;
  changeTab: (newValue: number) => void;
}

const HomePageCollapsedMenu = ({ openTabs, activeTab, changeTab }: HomePageCollapsedMenuProps) => {
  const [isNewConversationModalOpen, setIsNewConversationModalOpen] = useState(false);

  const {
    conversationStore: { setEmptyConversation, setMessageInput, setSelectedPromptId, setSelectedPromptModel },
    localizationStore: { i18next: i18n },
    modelStore: { setSelectedModel },
  } = useStore();
  const navigate = useNavigate();

  const handleCreateNewConversation = async () => {
    setSelectedPromptModel(false);
    setSelectedModel(null);
    setSelectedPromptId('');
    setEmptyConversation();
    setMessageInput('');
    navigate(`${Paths.CHAT}`);
    setIsNewConversationModalOpen(false);
  };

  const handleMenuItemClick = (tabValue: number) => {
    if (activeTab !== tabValue) {
      changeTab(tabValue);
    }
    openTabs();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        padding: '16px 8px',
      }}
    >
      <Stack sx={{ alignItems: 'center', padding: '0px 4px', gap: '24px', width: '100%' }}>
        <ListItemButton onClick={openTabs} sx={{ borderRadius: '8px', padding: '0px 8px' }} id="Chat-Menu-Icon">
          <MenuIcon style={{ width: 24, height: 24 }} />
        </ListItemButton>
        <MenuItem
          selected={activeTab === 0}
          onClick={() => handleMenuItemClick(0)}
          text={i18n.t('sidebarMenu.history.action')}
          icon={<HistoryIcon style={{ width: 24, height: 24 }} />}
        />
        <MenuItem
          selected={activeTab === 1}
          onClick={() => handleMenuItemClick(1)}
          text={i18n.t('sidebarMenu.sharedWithMe.action')}
          icon={<Chat style={{ width: 24, height: 24 }} />}
        />
      </Stack>
      <Button
        onClick={() => setIsNewConversationModalOpen(true)}
        id="newChat"
        sx={{
          width: 'fit-content',
          padding: '12px !important',
          height: '48px !important',
        }}
      >
        <AddIcon />
      </Button>
      <WarningModal
        isOpen={isNewConversationModalOpen}
        onClose={() => setIsNewConversationModalOpen(false)}
        heading={i18n.t('conversation.newConversationModal.heading')}
        description={i18n.t('conversation.newConversationModal.description')}
        confirmButton={
          <Button
            onClick={handleCreateNewConversation}
            variant={'contained'}
            sx={{ width: 'fit-content', height: '40px !important' }}
            id="startNewChat"
          >
            {i18n.t('conversation.startNew')}
          </Button>
        }
      />
    </div>
  );
  {
    /*<MenuItem*/
  }
  {
    /*  selected={activeTab === 2}*/
  }
  {
    /*  onClick={() => handleMenuItemClick(2)}*/
  }
  {
    /*  text={i18n.t('sidebarMenu.prompts.action')}*/
  }
  {
    /*  icon={<PeopleIcon style={{ width: 24, height: 24 }} />}*/
  }
  {
    /*/>*/
  }
  {
    /*<MenuItem*/
  }
  {
    /*  selected={activeTab === 2}*/
  }
  {
    /*  onClick={() => handleMenuItemClick(2)}*/
  }
  {
    /*  text={i18n.t('sidebarMenu.knowledgeBase.action')}*/
  }
  {
    /*  icon={<InsertDriveFileIcon style={{ width: 24, height: 24 }} />}*/
  }
  {
    /*/>*/
  }
};

export default HomePageCollapsedMenu;
