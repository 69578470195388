export const WorkflowRunStatuses = {
  READY: 'READY',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const AllWorkflows = {
  contractAnalysis: 'Contract Analysis',
  callTranscriptSummarization: 'Call Transcript Summarization',
};

export const exampleTypeDropdownValues = [
  {
    value: 'Good',
    name: 'Good',
  },
  {
    value: 'Bad',
    name: 'Bad',
  },
  {
    value: 'Neutral',
    name: 'Neutral',
  },
];

export const ContractAnalysisSteps = ['Contract', 'Terms', 'Finish'];
export const ContractAnalysisAddTermsSteps = ['Definition', 'Criteria', 'Examples', 'Sample Language'];
export const ContractAnalysisTermsStringSeparator = ';;';
export const ContractAnalysisSupportedFileTypes = ['txt', 'pdf', 'docx'];
export const ContractAnalysisDefaultInstruction = `#### Parties
* Definition: A party is a person or entity who takes part in a legal transaction or is involved in a legal agreement.
* Criteria: 
- Are the full legal names of all parties listed on the first page and in the signature block? 
- Are each party’s business addresses and the state of organization of each legal entity included? 
- Is a specific Brotherhood Mutual party indicated in the contract, possibly on the signature line? 
* Example:
#### Effective Date
* Definition: The specific date when the contract becomes legally binding and its terms begin to apply. Marks the point at which the parties need to begin fulfilling their obligations under the contract. 
* Criteria: 
- Specify the date on which the parties intend the agreement to be effective, considering whether the agreement is effective on execution, as of a specified past or future date; or on satisfaction of certain conditions.
* Example: 
#### Definitions
* Definition: In a legal context, “Definitions” refers to the specific meanings of terms used within legal documents or proceedings. In a contract, definitions provide specific meanings to terms or phrases to prevent ambiguity. From a contract review standpoint, this section is crucial as it sets the context for interpreting the rest of the contract.
* Criteria:
- Make sure important terms are defined properly and signified consistently throughout the contract by initial capitalization or in bold font or quotation marks.
* Example:

#### Term/Termination/Renewal
* Definition: The term refers to the duration of a contract. Termination refers to the end of the contract before the term is completed due to a breach or other reasons. Renewal refers to the continuation or extension of the contract after the term has ended.
* Criteria:
- Make sure the term of the contract is identified and there is a termination provision. If not, warn the reviewer.
- Identify whether the contract addresses cancellation or termination and how much notice is required.
- Identify whether penalties exist for early termination.
- Identify whether either party or both has the right to renew.
- Identify automatic renewal provisions and identify much notice must be given by parties who do not want to renew. Instruct the reviewer to note them in Docusign CLM.
- Identify if the contract has a term of longer than three years and suggest the client consider whether a shorter term is advisable. 

* Example 1:
(auto renewal, no renewal, cancel with notice, cancel at conclusion of term)

#### Parties’ Obligations
* Definition: Parties’ obligations refer to the duties, responsibilities, and tasks that each party has agreed to perform under a contract or under the terms of the contract.
* Criteria: 
- Review closely what Brotherhood Mutual’s obligations are to the other party(ies) and provide a brief summary.
- Review the other party’s/parties’ obligations to Brotherhood Mutual and provide a brief summary.
- As a part of that review, determine financial obligations: who is paying; what the payment is for; who receives the payment; when the payment will be made,; and how the payment should be made. 
- Non-financial obligations could include: terms related to exclusivity; ordering procedures; project management, including time frames, milestones, and project managers (names or job titles); shipment, delivery, acceptance, and inspection (in a supply of goods agreement), or scheduling of deliverables (in a services agreement); key performance indicators (commonly referred to as KPIs) or service level agreements (commonly referred to as SLAs); change orders; requirements to comply with marketing; and ownership status.
- Warn the reviewer about obligations that seem vague or don’t include any reference to length of time.
- Warn the reviewer if the contract involves a third-party service provider having access to our information systems. 


* Example:

#### Independent Contractor
* Definition: An independent contractor is a self-employed person or entity contracted to perform work for—or provide services to—another entity as a non-employee. They are hired by employers to accomplish certain tasks but do not have the right of control over those entities. They are required to maintain their own insurance, including workers compensation insurance, as well as pay their own taxes, unemployment, and benefits, among other things
* Criteria:
- Identify whether an independent contractor relationship is involved and analyze whether this is made clear in the contract.
- Make sure the contract explains this so the parties understand the differences associated with working with an independent contractor vs. an employee.  
- If the agreement is between Brotherhood Mutual and another Company, this clause isn’t needed. If it’s with a Contractor, Vendor, or Consultant, it should be included.

* Example 1: Contractor has the required skill, experience, and qualifications to perform the Services;
Contractor shall perform the Services in a professional and workmanlike manner in accordance with generally recognized industry standards for similar services; 
Contractor shall devote sufficient resources to ensure that the Services are performed in a timely and reliable manner; 
Contractor shall perform the Services in compliance with all applicable federal, state, and local laws and regulations; 
Contractor acknowledges and agrees that the Services to be performed shall be provided as an independent contractor. As an independent contractor, Contractor further; acknowledges and agrees that (i) Company will not withhold any federal, state, or local income taxes, payroll taxes, or similar taxes from payments to be made to Contractor, and the payment of all such taxes is the sole responsibility of Contractor; (ii) Contractor is not entitled to receive unemployment or workers’ compensation benefits from Company, and (iii) Contractor is not entitled to receive any fringe benefits, retirement benefits, or other employee benefits of any kind from the Company; 
Contractor agrees that it will notify Company immediately in writing in the event Contractor believes that its services can no longer be performed as an independent contractor, but instead must be performed as an employee of Company; 
Contractor hereby indemnifies and holds harmless Company from any and all damages, liabilities, costs, penalties, or expenses (including attorney’s fees) suffered or incurred by Company in connection with (i) Contractor’s failure to timely pay any income taxes, self-employment taxes, payroll taxes, or other taxes due as a result of Services provided hereunder by Contractor; and (ii) the performance by Contractor of the Services hereunder; and 
Upon termination of this Agreement, Contractor shall return to Company any and all documents or other tangible property or assets that belong to Company or its customers.

#### Attorney’s Fees/Expenses
* Definition: Attorney’s Fees/Expenses refer to the amounts billed to a client for legal services performed on their behalf or the payment of one’s legal costs by another party, often as the result of a judgment.
* Criteria:
- Analyze whether each party is required to pay its own attorney fees and expenses or whether the prevailing party’s expenses will be reimbursed by the losing party.
* Example:

#### Assignment
* Definition: Assignment is a legal term whereby an individual, the “assignor,” transfers rights, property, or other benefits to another known as the “assignee”. It refers to the transfer of rights or property from one party to another.
* Criteria:
- Summarize whether the rights or obligations under the contract can be assigned to other parties. 
* Example:

#### Indemnification
* Definition: To indemnify, also known as indemnity or indemnification, means compensating a person for damages or losses they have incurred or will incur related to a specified accident, incident, or event. It refers to protection against liability and requires the at-fault counterparty to compensate the responsible counterparty for all losses and damages caused by an early termination.
* Criteria:
- Identify each party’s indemnification obligations. 
- Identify if the obligation extend to non-parties to the agreement (e.g., party’s employees, officers, directors, affiliates, representatives, and customers)
- Identify what types of claims and losses are covered
- Identify the types of damages covered or not covered (e.g., negligence, breach of contract, willful misconduct) 
- Identify the scope of indemnification (e.g., potential losses, costs, damages, expenses, including but not limited to legal fees and court costs)
- Identify any caps or limitation on the indemnification obligation within the provision itself. 
- Indemnification provisions should cover Brotherhood Mutual for damages caused by the other party’s own negligence, breach of contract or willful misconduct. Warn the reviewer if this is not the case and suggest alternate language.
- Ideally, we like for the outside party to indemnify, hold harmless, and defend Brotherhood Mutual for damages caused by the outside party’s own negligence, breach of contract, or willful misconduct. If there is a one-sided provision that provides no protection to Brotherhood Mutual, then often we like to request that a mutual indemnification provision (protecting both parties) be added to the agreement so that the interests of Brotherhood Mutual are better protected. This is the most important part of your analysis of this provision and you must address it.  
- Indemnification language in favor of the other party should include an exception for the other party’s own negligence, recklessness, or willful misconduct. If it’s missing, suggest adding language to address this concern.
* Good Example 1: Company will hold harmless, defend and indemnify Brotherhood Mutual against liability, including reasonable attorney’s fees and costs of defense, imposed on Brotherhood Mutual by law for damages sustained that result from Company’s (including Company’s agents, employees and representatives) negligent acts, errors or omissions, or Company’s material breach of this Agreement. Brotherhood Mutual will hold harmless, defend and indemnify Company against liability, including reasonable attorney’s fees and costs of defense, imposed on Company by law for damages sustained that result from Brotherhood Mutual’s negligent acts, errors or omissions, or Brotherhood Mutual’s material breach of this Agreement, but only if Company has not caused or contributed to such loss by Company’s own negligent acts, errors or omissions. 
* Good Example 1 Explanation:
The indemnification provision is good as it clearly outlines each party’s obligations, covers damages due to negligence or breach of contract, and provides mutual protection.
* Good Example 2: Company agrees to indemnify, defend, and hold harmless Brotherhood Mutual for all liability and costs (including costs of defense) arising directly or indirectly out of Company’s negligence, breach of contract, or willful misconduct as related to Company’s obligations under the Agreement. 

Brotherhood Mutual agrees to indemnify, defend, and hold harmless Company for all liability and costs directly arising from Brotherhood Mutual’s gross negligence in performing any of the activities contemplated by this Agreement or Brotherhood Mutual’s material breach of this Agreement; provided that the amount of any such indemnity shall be capped at the aggregate amount of fees received by Company pursuant to this Agreement.
* Good Example 2 Explanation: Mutual indemnification with both parties agreeing to hold each other harmless; Covers negligence, breach of contract, willful misconduct; Caps Brotherhood Mutual's indemnity at fees paid to Company
* Good Example 3: Contractor agrees to defend, indemnify, and hold harmless Brotherhood Mutual (including Brotherhood Mutual’s agents, employees, and representatives), for any and all claims arising out of any injury or damage including, but not limited to, bodily injury, personal injury, emotional injury, or property damage which may result to any person or organization because of the performance pursuant to the Agreement by Contractor or any of Contractor’s employees, contractors, or other agents, except to the extent that such injury or damage results from the negligence of Brotherhood Mutual (including Brotherhood Mutual’s agents, employees and representatives).
* Good Example 3 Explanation: One-sided indemnification with Contractor indemnifying Brotherhood Mutual; Covers bodily injury, property damage, etc. caused by Contractor; Excepts damages caused by Brotherhood Mutual's negligence
* Bad Example:
* Bad Example Explanation:
* Sample Language: Company will hold harmless, defend and indemnify Brotherhood Mutual against liability, including reasonable attorney’s fees and costs of defense, imposed on Brotherhood Mutual by law for damages sustained that result from Company’s (including Company’s agents, employees and representatives) negligent acts, errors or omissions, or Company’s material breach of this Agreement. Brotherhood Mutual will hold harmless, defend and indemnify Company against liability, including reasonable attorney’s fees and costs of defense, imposed on Company by law for damages sustained that result from Brotherhood Mutual’s negligent acts, errors or omissions, or Brotherhood Mutual’s material breach of this Agreement, but only if Company has not caused or contributed to such loss by Company’s own negligent acts, errors or omissions. 

#### Limitation of Liability
* Definition: Limitation of Liability refers to a provision in a contract that limits the amount of exposure a company faces in the event of a claim or lawsuit. Parties attempt to limit their liability through these provisions. It also refers to a cap on the amount of money that a party may be required to pay in the event of certain types of losses, damages, or injuries.
* Criteria:
- Be aware of a party trying to limit liability for their own negligence, reckless, or willful misconduct and whether Brotherhood Mutual’s liability is limited in the same way under the contract. We want the other party responsible for their own negligence and willful misconduct.
- Analyze whether a waiver of indirect damages is included. 
- Analyze whether the whether the indemnification obligations are limited under this provision as well. Inform the reviewer.
- Analyze whether the limitation is one-sided (only applies to Brotherhood Mutual) or mutual (applies to both parties). This will depend on the nature of the indemnification obligations. If Brotherhood Mutual has indemnification obligations, then it would be a good idea to request that the Brotherhood Mutual’s indemnification obligation be limited to the amount contained in the limitation of liability provision. However, if the outside party has an obligation to indemnify Brotherhood Mutual, then it is preferable for their indemnification obligation not be limited by the limitation of liability provision.
- Any Brotherhood Mutual indemnification obligations should try to be limited to the amount contained in the limitation of liability provision, and it’s preferable for any outside party indemnification obligations to not be limited by the limitation of liability provision (consider adding an exception to the limitation of liability provision). If this is not the case, suggest language that limits Brotherhood Mutual’s liability to a fixed amount or the amount equal to its corporate liability insurance which is $x per occurrence, $y in the aggregate.
* Good Example:
* Good Example Explanation:
* Bad Example:
* Bad Example Explanation:
* Sample Language: 

#### Insurance
* Definition: Insurance is a clause or provision within a contract that outlines the requirement for one or more parties to maintain adequate insurance coverage. This coverage is intended to manage specific risks that could arise during the execution of the contract. The clause may specify the type of insurance required (such as liability insurance, property insurance, professional indemnity insurance, etc.), the minimum coverage limits, and may also require that one party be named as an additional insured on the other party’s policy. The purpose of this clause is to ensure that funds will be available to cover losses, damages, or liabilities that could occur during the fulfillment of the contract.
* Criteria:
- Identify any requirements for Brotherhood Mutual to carry coverage, including minimum coverage limits required and minimum quality standards (e.g., A.M. Best rating). 
- Inform the reviewer to check these against our company’s policy limits to ensure compliance.
- Identify any mention of a waiver of subrogation and describe it.
- Identify any requirements to name other parties as an additional insured. 
- If the contract involves someone providing services to Brotherhood Mutual or agreeing to indemnify us, we should require them to maintain adequate liability insurance (including incidental contractual liability coverage). Alert the reviewer and suggest language if this is not the case. 
- If no insurance provision is included, suggest language that requires the other party to maintain adequate liability insurance (including incidental contractual liability coverage) and workers’ compensation insurance. The other party’s insurance policy should also list Brotherhood Mutual as an additional insured.

* Good Example:
* Good Example Explanation:
* Bad Example:
* Bad Example Explanation:

* Sample Language: 
`;
export const ContractAnalysisDefaultInstruction2 = `#### Confidentiality
* Definition: Confidentiality in a legal context often refers to the obligation of an individual or organization to safeguard entrusted information. A confidentiality agreement is a legal contract that binds one or more parties to non-disclosure of confidential or proprietary information. It is often used in situations where sensitive corporate information or proprietary knowledge is not to be made available to the general public or to competitors.
* Criteria:
-  Check if the contract clearly defines the scope of the confidentiality obligation. It should specify what is considered confidential information, what is not, and any exceptions to the confidentiality obligation.
- If no confidentiality provision is present, suggest sample language. Brotherhood Mutual has a strong desire to keep its trade secrets and certain information confidential if this type of information will be shared under the agreement.
- The contract should outline the obligations of the party receiving the confidential information. This could include stipulations about how the information can be used, who it can be disclosed to, and what steps the receiving party must take to protect the information.
- The contract should specify the consequences of a breach of the confidentiality agreement. This could include legal remedies such as injunctions, damages, and attorney’s fees.
- Identify if the contract addresses ownership and use of the Company’s intellectual property. If it does, summarize requirements pertaining to intellectual property, including ownership, exclusivity, and any limitations on use. 
- Analyze whether “confidential information” and “trade secrets” separately defined and how long is such type of information to remain confidential. Include this in your summary.
- Although Brotherhood Mutual typically likes to see all confidential information be safeguarded in perpetuity, some courts may find that a perpetual obligation to hold confidential information (not including trade secrets) in confidence forever to be problematic.
- Tell the reviewer to check if Brotherhood Mutual have an NDA with the party.
- Tell the reviewer to refer to the contract’s governing law provision and check Legal’s 50-state survey on confidentiality laws regarding how long confidential information and trade secrets can be protected as confidential. 

* Good Example 1:
“Confidential Information” means and includes, without limitation, information marked “Confidential,” “Secret,” or the like that is delivered with an oral or written indication of its confidentiality, or that the Receiving Party should reasonably understand is not public information that is disclosed by the Disclosing Party to the Receiving Party relating in any way to the Disclosing Party’s business or customers. Confidential Information shall not include information that:

is at the time of disclosure, or thereafter becomes, through a source other than the Receiving Party, publicly known;
is lawfully received from a third-party having rights in such information without restriction on the right of such third party or the Receiving Party to disseminate the information and without notice of any restriction against its further disclosure;
was known to the Receiving Party, without any proprietary restrictions, at the time of disclosure;
is developed independently by the Receiving Party without reliance on or reference to any information disclosed pursuant to this Agreement; or
constitutes a Trade Secret.

“Trade Secret” means any information of the Disclosing Party that is: (1) not generally or publicly known; and (2) has economic value to the Disclosing Party by virtue of being not generally or publicly known. By way of example, a Trade Secret for the purposes of this Agreement includes,  but is  not limited to: non-public data, reports, analyses, compilations, studies, interpretations, forecasts, records, information on finances, rates, rules, policy forms, customer data or potential customer data, personnel information, customer lists, marketing plans, strategies, techniques, programs, systems, and other materials; non¬public information concerning the products, services, costs, prices, discounts, sales, properties, assets and liabilities; business processes such as rating processes, computer system processes, and strategic plans.

This Agreement shall continue in force for two (2) years from the Effective Date, or until thirty (30) days after either Party delivers to the other Party written notice of termination, whichever occurs first.

With respect to any Confidential Information, while this Agreement is in effect and for three (3) years after its termination, the Receiving Party agrees not to use for any purpose whatsoever or disclose Confidential Information disclosed by the Disclosing Party, except as necessary for the Parties to evaluate a transaction and/or business relationship between the Parties, and/or perform the terms of this Agreement.

All Trade Secret information shall be safeguarded by the Receiving Party as required by this Agreement for the period that such information retains its status as a Trade Secret under applicable law.

* Good Example 1 Explanation:
The confidentiality clause provides clear definitions of "Confidential Information" and "Trade Secrets", distinguishing between the two categories. It outlines obligations on the receiving party, states available remedies for breaches, and specifies time periods for protections. The definitions are thorough and the multi-tiered approach for types of information demonstrates a strong confidentiality clause.

* Bad Example 1: “Confidential Information” means and includes, without limitation, information marked “Confidential,” “Secret,” or the like that is delivered with an oral or written indication of its confidentiality, or that the Receiving Party should reasonably understand is not public information that is disclosed by the Disclosing Party to the Receiving Party relating in any way to the Disclosing Party’s business or customers. Confidential Information shall not include information that:

is at the time of disclosure, or thereafter becomes, through a source other than the Receiving Party, publicly known;
is lawfully received from a third-party having rights in such information without restriction on the right of such third party or the Receiving Party to disseminate the information and without notice of any restriction against its further disclosure;
was known to the Receiving Party, without any proprietary restrictions, at the time of disclosure;
is developed independently by the Receiving Party without reliance on or reference to any information disclosed pursuant to this Agreement; or
constitutes a Trade Secret.

All Trade Secret information shall be safeguarded by the Receiving Party as required by this Agreement for the period that such information retains its status as a Trade Secret under applicable law.

* Bad Example 1 Explanation: The Agreement contains confidentiality language that protects the “Confidential Information” of both the Company and Brotherhood Mutual. However,  the Agreement did not indicate a specific timeframe in which the parties are required to keep Confidential Information confidential.  This is especially important after the Agreement ends. The Agreement also does not define trade secrets. We typically recommend separately defining confidential information and trade secrets including a specific timeframe with respect to protecting confidential information and trade secrets after the termination of the Agreement for keeping information confidential. Although we typically like to see all confidential information be safeguarded in perpetuity, some courts may find that a perpetual obligation to hold confidential information (not including trade secrets) in confidence forever may be an undue restraint on trade, and therefore invalidate the agreement, or a portion thereof. Therefore, if state law allows, it would be wise to include a time limit with respect to protecting confidential information after the termination of the Agreement (e.g. two (2) years after termination of the Agreement), but require that trade secrets be safeguarded in perpetuity.  You also will want to request that confidential information and trade secrets be separately defined.

* Sample Language: “Confidential Information” means and includes, without limitation, information marked “Confidential,” “Secret,” or the like that is delivered with an oral or written indication of its confidentiality, or that the Receiving Party should reasonably understand is not public information that is disclosed by the Disclosing Party to the Receiving Party relating in any way to the Disclosing Party’s business or customers. Confidential Information shall not include information that:

is at the time of disclosure, or thereafter becomes, through a source other than the Receiving Party, publicly known;
is lawfully received from a third-party having rights in such information without restriction on the right of such third party or the Receiving Party to disseminate the information and without notice of any restriction against its further disclosure;
was known to the Receiving Party, without any proprietary restrictions, at the time of disclosure;
is developed independently by the Receiving Party without reliance on or reference to any information disclosed pursuant to this Agreement; or
constitutes a Trade Secret.

“Trade Secret” means any information of the Disclosing Party that is: (1) not generally or publicly known; and (2) has economic value to the Disclosing Party by virtue of being not generally or publicly known. By way of example, a Trade Secret for the purposes of this Agreement includes,  but is  not limited to: non-public data, reports, analyses, compilations, studies, interpretations, forecasts, records, information on finances, rates, rules, policy forms, customer data or potential customer data, personnel information, customer lists, marketing plans, strategies, techniques, programs, systems, and other materials; non¬public information concerning the products, services, costs, prices, discounts, sales, properties, assets and liabilities; business processes such as rating processes, computer system processes, and strategic plans.

This Agreement shall continue in force for two (2) years from the Effective Date, or until thirty (30) days after either Party delivers to the other Party written notice of termination, whichever occurs first.

With respect to any Confidential Information, while this Agreement is in effect and for three (3) years after its termination, the Receiving Party agrees not to use for any purpose whatsoever or disclose Confidential Information disclosed by the Disclosing Party, except as necessary for the Parties to evaluate a transaction and/or business relationship between the Parties, and/or perform the terms of this Agreement.

All Trade Secret information shall be safeguarded by the Receiving Party as required by this Agreement for the period that such information retains its status as a Trade Secret under applicable law.


#### Governing Law
* Definition: Governing Law refers to the jurisdiction’s laws that will be used to interpret the contract and resolve disputes. Governing law refers to the rules and regulations that will be used to interpret the contract and resolve disputes. 
* Criteria:
- Identify the governing jurisdiction. Brotherhood Mutual prefers Indiana law govern its contracts because we are more familiar with Indiana law and costs may be greater to resolve disputes in a foreign jurisdiction. 
- The contract should specify how disputes will be handled, including the timeframe in which disputes must be brought.  
- Identify dispute resolution methods (e.g., mediation, arbitration, litigation, etc.) if they are present in this provision. Brotherhood Mutual typically prefers to see alternative dispute resolution methods over litigation. 
- Identify if the agreement addresses a conflict of laws.
- If no provision is included, recommend sample language. 
* Good Example 1: This Agreement shall be construed and interpreted in accordance with the laws of the state of Indiana, without giving effect to any choice or conflict of law provision or rule (whether of the state of Indiana or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than the state of Indiana. Each party to this Agreement submits to the jurisdiction of the state and federal courts in Indiana, and to the venue in Fort Wayne, Indiana, for the purpose of any action or proceeding arising out of or related to this Agreement.
* Good Example 1 Explanation: The clause specifies Indiana law will govern the contract, which is preferred by the company. It establishes jurisdiction and venue in Indiana courts, avoiding potentially higher costs of foreign jurisdictions. The clause sets out submission to Indiana state and federal courts for disputes, designating a specific venue.
* Bad Example: [another state]
* Bad Example Explanation: Another state’s law governs this Agreement. This could create difficulties and additional expenses for Brotherhood Mutual, such as finding an attorney licensed to practice law in that state and traveling there to litigate any disputes.
* Sample Language: This agreement shall be construed and interpreted in accordance with the laws of the state of Indiana, without giving effect to any choice or conflict of law provision or rule (whether of the state of Indiana or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than the state of Indiana.  Each party to this agreement submits to the jurisdiction of the state and federal courts in Indiana, and to the venue in Fort Wayne, Indiana, for the purpose of any action or proceeding arising out of or related to this agreement.

#### Notice
* Definition: Notice in a legal context often refers to the formal communication of a fact or action as required by a contract or law. A notice is a formal announcement or declaration about something. In legal terms, it can refer to a formal notification of the end of a contract between two or more parties.
* Criteria:
- A specific person or title should be listed as the notice person for Brotherhood Mutual companies. Identify this.
- The method of providing notice should be modern (e.g., notice via email rather than only overnight or fax).
* Good Example:
* Good Example Explanation:
* Bad Example:
* Bad Example Explanation:
* Sample Language: 

#### Amendment/Modification
* Definition: Amendment/Modification refers to the change or alteration of the terms of a contract with the consent of all parties involved. It outlines the process and conditions under which changes can be made to the contract terms.
* Criteria:
- Only written modifications should be allowed to prevent the other party from arguing an oral modification was made is recommended. Such a provision is sometimes combined with the waiver clause.
* Good Example:
* Good Example Explanation:
* Bad Example:
* Bad Example Explanation:
* Sample Language:

#### Force Majeure
* Definition: Force Majeure refers to a clause that frees both parties from liability or obligation when an extraordinary event or circumstance beyond the control of the parties occurs. Force majeure is a clause that is included in contracts to remove liability for unforeseeable and unavoidable catastrophes that interrupt the expected course of events and prevent participants from fulfilling obligations. These clauses generally cover natural disasters, such as hurricanes, tornadoes, and earthquakes, as well as human actions, such as armed conflict and man-made diseases.
* Criteria:
- Summarize the conditions in which Force Majeure apply and how they affect each parties’ obligations, including financial impacts.
- Analyze whether COVID-19, pandemics, or other potentially contentious scenarios are included. 
- Depending on Brotherhood Mutual’s position in the contract, the clause should be broad enough to excuse performance, or narrow enough to enforce performance.
* Good Example: Neither party shall be liable for any failure or delay in the performance of its obligations under the Agreement on account of an act of God, act of nature or terrorism, action by any governmental entity, fire, insurrection, riot, explosion, strikes, pandemic (including COVID-19 and related illnesses) or any condition beyond the reasonable control of a Party (“Force Majeure Event”). If a Force Majeure Event prevents the occurrence of event(s) contemplated herein or results in a performance delay by a Party for longer than thirty (30) days, either Party may terminate this Agreement without penalty upon written notice to the other Party. In the event of a Force Majeure Event, where Brotherhood Mutual has already made any payments under the Agreement, including any nonrefundable payments, the Company shall promptly refund those payments, and any future payments Brotherhood Mutual owed to the Company under the Agreement are canceled.
* Good Example Explanation: Defines Force Majeure events including pandemics and COVID-19. Allows termination if event lasts over 30 days without penalty. Addresses financial impacts with refunds and payment cancellations.
* Bad Example:
* Bad Example Explanation:
* Sample Language: Neither party shall be liable for any failure or delay in the performance of its obligations under the Agreement on account of an act of God, act of nature or terrorism, action by any governmental entity, fire, insurrection, riot, explosion, strikes, pandemic (including COVID-19 and related illnesses) or any condition beyond the reasonable control of a Party (“Force Majeure Event”). If a Force Majeure Event prevents the occurrence of event(s) contemplated herein or results in a performance delay by a Party for longer than thirty (30) days, either Party may terminate this Agreement without penalty upon written notice to the other Party. In the event of a Force Majeure Event, where Brotherhood Mutual has already made any payments under the Agreement, including any nonrefundable payments, the Company shall promptly refund those payments, and any future payments Brotherhood Mutual owed to the Company under the Agreement are canceled.
#### Dispute Resolution
* Definition: Dispute Resolution refers to the process that parties agree to use to resolve disputes, such as arbitration or litigation. Dispute resolution refers to the process of resolving disputes between parties. It includes methods like mediation, arbitration, negotiation, and litigation.
* Criteria:
- Check if the clause specifies a method or methods for resolving disputes, such as mediation, arbitration, litigation, etc.
- Identify if the dispute resolution process is clearly defined, including rules to be followed, number of arbitrators, and how they will be selected.
- Confirm if the clause states that the chosen dispute resolution method(s) will be the sole remedy for controversies or claims arising from the agreement.
- Look for a waiver of the right to file lawsuits, with arbitration decisions being enforceable in court.
- For arbitration, require that arbitrators have relevant expertise in the nature of potential disputes.
- For mediation or arbitration, specify if a particular administering organization's rules will be followed.
- If no provision is included, suggest sample language. It’s important for this provision to be included because without it, disputes will likely be taken to court for a bench trial.
- Analyze if the process sets clear timeframes for initiating dispute resolution.
* Good Example 1: The Parties agree that any claim or dispute arising from or related to this Agreement shall be settled by mediation, or, if necessary, legally binding arbitration in accordance with the rules of the American Arbitration Association. Judgment upon any arbitration decision may be entered in any court otherwise having jurisdiction.  The Parties understand that these methods shall be the sole remedy for any controversy or claim arising out of this Agreement and expressly waive their right to file a lawsuit in any civil court against one another for such disputes, except to enforce an arbitration decision. The arbitration shall be heard and decided by a panel of three (3) arbitrators selected in the following manner. Each Party shall, within fifteen (15) days after written notice is given by one Party requesting arbitration, select one arbitrator. The arbitrators so selected shall within fifteen (15) days after the last has been selected, jointly select a neutral third arbitrator to complete the panel. Each arbitrator selected pursuant to this section shall at a minimum be competent to arbitrate disputes of the kind arising from this Agreement.
* Good Example Explanation: Specifies mediation and arbitration under AAA rules as sole remedy. Defines arbitration process for selecting arbitrators and states arbitration decisions can be enforced in court. Overall, sets out clear, detailed arbitration process.
* Good Example 2: The Parties agree that any claim or dispute arising from or related to this Agreement shall be settled by biblically based mediation or, if necessary, legally binding arbitration in accordance with the Rules of Procedure for Christian Conciliation of the Institute for Christian Conciliation. Judgment upon any arbitration decision may be entered in any court otherwise having jurisdiction. The Parties understand that these methods shall be the sole remedy for any controversy or claim arising out of this Agreement and expressly waive their right to file a lawsuit in any civil court against one another for such disputes, except to enforce an arbitration decision. The arbitration shall be heard and decided by a panel of three (3) arbitrators selected in the following manner. Each Party shall, within fifteen (15) days after written notice is given by one Party requesting arbitration, select one arbitrator. The arbitrators so selected shall within fifteen (15) days after the last has been selected, jointly select a neutral third arbitrator to complete the panel. Each arbitrator selected pursuant to this section shall at a minimum be competent to arbitrate disputes of the kind arising from this Agreement.
* Good Example 2 Explanation: Specifies biblically-based mediation and arbitration under Institute for Christian Conciliation rules. Defines similar arbitration process as Example 1. Sets mediation/arbitration as sole remedy, with arbitration enforceable in court. Provides clear process tailored for Christian conciliation.
* Bad Example: (trial by jury, law of another state)
* Bad Example Explanation:
* Sample Language: Regarding disputes related to this agreement, a three-step process is agreed to by the parties.  The parties will first attempt through earnest discussion to resolve the differences, including providing notice of the dispute and involving appropriate levels of management of both parties.  Failing resolution, the parties will then participate in mediation.  Failing resolution through mediation, only then will any dispute be handled in accordance to the governing law section of this Agreement. 

The Parties agree that any claim or dispute arising from or related to this Agreement shall be settled by mediation, or, if necessary, legally binding arbitration in accordance with the rules of the American Arbitration Association.  Judgment upon any arbitration decision may be entered in any court otherwise having jurisdiction.  The Parties understand that these methods shall be the sole remedy for any controversy or claim arising out of this Agreement and expressly waive their right to file a lawsuit in any civil court against one another for such disputes, except to enforce an arbitration decision.  The arbitration shall be heard and decided by a panel of three (3) arbitrators selected in the following manner.  Each Party shall, within fifteen (15) days after written notice is given by one Party requesting arbitration, select one arbitrator.  The arbitrators so selected shall within fifteen (15) days after the last has been selected, jointly select a neutral third arbitrator to complete the panel.  Each arbitrator selected pursuant to this section shall at a minimum be competent to arbitrate disputes of the kind arising from this Agreement.

The Parties agree that any claim or dispute arising from or related to this Agreement shall be settled by biblically based mediation, or, if necessary, legally binding arbitration in accordance with the Rules of Procedure for Christian Conciliation of the Institute for Christian Conciliation.  Judgment upon any arbitration decision may be entered in any court otherwise having jurisdiction.  The Parties understand that these methods shall be the sole remedy for any controversy or claim arising out of this Agreement and expressly waive their right to file a lawsuit in any civil court against one another for such disputes, except to enforce an arbitration decision.  The arbitration shall be heard and decided by a panel of three (3) arbitrators selected in the following manner.  Each Party shall, within fifteen (15) days after written notice is given by one Party requesting arbitration, select one arbitrator.  The arbitrators so selected shall within fifteen (15) days after the last has been selected, jointly select a neutral third arbitrator to complete the panel.  Each arbitrator selected pursuant to this section shall at a minimum be competent to arbitrate disputes of the kind arising from this Agreement.`;
