export enum SortOptionsTeams {
  fromAtoZ = 'fromAtoZ',
  fromZtoA = 'fromZtoA',
  mostToLeastActive = 'mostToLeastActive',
  leastToMostActive = 'leastToMostActive',
}

export enum SortOptionsUsers {
  fromAtoZ = 'fromAtoZ',
  fromZtoA = 'fromZtoA',
  mostToLeastActive = 'mostToLeastActive',
  leastToMostActive = 'leastToMostActive',
}

export enum SortOptionsContracts {
  nameAtoZ = 'fromAtoZ',
  nameZtoA = 'fromZtoA',
  oldestFirst = 'oldestFirst',
  newestFirst = 'newestFirst',
  ownerAtoZ = 'ownerAtoZ',
  ownerZtoA = 'ownerZtoA',
}
