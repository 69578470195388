import { WorkflowRun } from '../models/Workflows';

export const SortContractFormulas = {
  fromAtoZ: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort((contract1, contract2) => contract1?.variables?.name?.localeCompare(contract2?.variables?.name)),
  fromZtoA: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort((contract1, contract2) => contract2?.variables?.name?.localeCompare(contract1?.variables?.name)),
  ownerAtoZ: (contract: any[]) =>
    contract.slice().sort((contract1, contract2) => contract1?.user?.email.localeCompare(contract2?.user?.email)),
  ownerZtoA: (contract: any[]) =>
    contract.slice().sort((contract1, contract2) => contract2?.user?.email.localeCompare(contract1?.user?.email)),
  oldestFirst: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort(
        (contract1, contract2) => new Date(contract1?.startedAt).getTime() - new Date(contract2?.startedAt).getTime()
      ),
  newestFirst: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort(
        (contract1, contract2) => new Date(contract2?.startedAt).getTime() - new Date(contract1?.startedAt).getTime()
      ),
};
