import { Box, Typography, IconButton, TextField, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';

import { useStore } from '../../../hooks/useStore';
import { COLOR_BORDER_PRIMARY, COLOR_RED, COLOR_WHITE } from '../../../constants/colors';
import Button from '../../../components/buttons/Button';
import { exampleTypeDropdownValues } from '../../../constants/workflows';

const BoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const ErrorPTag = styled.p`
  color: ${COLOR_RED};
`;

const ContractTermExamples = ({
  onSubmit,
  closeExampleFields,
}: {
  onSubmit: (data: any) => void;
  closeExampleFields: () => void;
}) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      exampleType: '',
      exampleName: '',
      explanation: '',
      exampleLanguageExamples: [{ title: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'exampleLanguageExamples',
  });

  const handleSubmitData = async (data: any) => {
    onSubmit(data);
    closeExampleFields();
    reset({
      exampleName: '',
      explanation: '',
      exampleLanguageExamples: [],
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitData)}>
      <BoxContainer>
        <Controller
          control={control}
          name={'exampleType'}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <TextField select label="Type" onChange={onChange} value={value} ref={ref}>
              {exampleTypeDropdownValues.map((item, index) => (
                <MenuItem key={`example-type-${index}`} value={item.value}>
                  <Typography variant={'subtitle2'}>{item.name}</Typography>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {errors?.exampleType?.type === 'required' && (
          <ErrorPTag>{i18n.t('workFlows.contractAnalysis.errorMessage')}</ErrorPTag>
        )}

        <Controller
          control={control}
          name={'exampleName'}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <TextField label="Example Name" onChange={onChange} value={value} ref={ref} />
          )}
        />
        {errors?.exampleName?.type === 'required' && (
          <ErrorPTag>{i18n.t('workFlows.contractAnalysis.errorMessage')}</ErrorPTag>
        )}

        <Controller
          control={control}
          name={'explanation'}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <TextField label="Explanation" onChange={onChange} value={value} ref={ref} />
          )}
        />
        {errors?.explanation?.type === 'required' && (
          <ErrorPTag key={'explanationError'}>{i18n.t('workFlows.contractAnalysis.errorMessage')}</ErrorPTag>
        )}

        <Box sx={{ border: `1px solid ${COLOR_BORDER_PRIMARY}`, padding: '12px', borderRadius: '8px' }}>
          {fields.map((field: any, index: number) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
              <Box flex={11} key={field.id}>
                <Controller
                  control={control}
                  name={`exampleLanguageExamples.${index}.title`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <TextField
                      label="Example Language Examples"
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                {errors?.exampleLanguageExamples?.[index]?.title?.type === 'required' && (
                  <ErrorPTag>{i18n.t('workFlows.contractAnalysis.errorMessage')}</ErrorPTag>
                )}
              </Box>
              {fields.length > 1 && (
                <Box flex={1}>
                  <IconButton
                    id="removeLanguageExamples"
                    onClick={() => remove(index)}
                    sx={{
                      background: 'blue',
                      color: COLOR_WHITE,
                      borderRadius: '8px',
                      ':hover': { background: COLOR_RED },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
          <Box sx={{ marginTop: '1rem', justifyContent: 'right', display: 'flex' }}>
            <Button
              id="addLanguageExamples"
              onClick={() => {
                append({ title: '' });
              }}
              sx={{ width: 'auto' }}
            >
              {i18n.t('workFlows.contractAnalysis.stepper.example.addLanguageExamples')}
            </Button>
          </Box>
        </Box>
        <Button
          sx={{
            padding: '9px 16px !important',
            height: '40px !important',
            width: 'fit-content',
            fontWeight: 400,
            marginRight: '8px',
          }}
          type="submit"
          onClick={handleSubmit(handleSubmitData)}
        >
          {i18n.t('workFlows.contractAnalysis.stepper.example.addExamples')}
        </Button>
      </BoxContainer>
    </form>
  );
};

export default observer(ContractTermExamples);
